@mixin default-color-palette {
  --navlink-color: white;
  --navlink-color-hover: var(--secondary-light-blue);
  --background-main-color: var(--secondary-blue);
}

.sidebar {
  @include default-color-palette();

  padding: 1rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - var(--navbar-top-height));
  width: var(--sidebar-width);

  position: fixed;
  top: var(--navbar-top-height);
  left: 0;
  background-color: var(--background-main-color);

  &.broker-colors {
    @include default-color-palette();
  }

  &.insured-colors {
    @include default-color-palette();
  }

  &.admin-colors {
    --navlink-color: white;
    --navlink-color-hover: var(--primary-light-blue);
    --background-main-color: var(--primary-dark-blue);
  }

  // Mobile style
  @include mobile {
    box-sizing: border-box;
    min-height: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.5s;
    padding-top: calc(var(--navbar-top-height) * 1.5);

    &.show {
      display: flex;
      background-color: transparent;
      top: 0;
      opacity: 1;
      z-index: 999;
    }
  }

  .nav {
    flex-direction: column;
    max-height: 66.3vh;
    overflow-y: auto;
    flex-wrap: nowrap;
    @include scrollbar($gray-700);
    margin-right: 0.5rem;
    .nav-link {
      color: var(--navlink-color);
      font-weight: bold;
      font-size: 1.4rem;
      width: 100%;
      padding: 1.5rem 0;
      transition: 0.3s;
      box-sizing: border-box;
      border-bottom-right-radius: 3rem;
      border-top-right-radius: 3rem;
      border: 2px solid transparent;

      // Mouse interactions events
      .link-menu {
        padding: 0 4rem;
        display: flex;
        align-items: center;
      }
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: var(--navlink-color-hover);
        .icon-btn {
          transition: 0.3s;
          background-color: var(--navlink-color-hover);
          color: var(--secondary-blue);
          opacity: 1;
        }
      }
      &:focus {
        outline: none;
        border: 2px solid var(--navlink-color-hover);
        border-left-color: transparent;
      }
    }
  }

  .link-menu--badge {
    background-color: var(--warning-yellow);
    margin-left: 1rem;
    color: var(--black);
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 900;
    font-size: 1.2rem;
  }

  .sidebar-admin-button {
    position: absolute;
    padding: 0 4rem;
    bottom: 10%;
    width: 100%;
    .btn {
      background-color: lighten($secondary-blue, 10%);

      &:hover {
        background-color: lighten($secondary-blue, 20%);
      }
    }
  }
  .close-box {
    display: none;

    @include mobile {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .nav-accordion {
    background-color: transparent;
    border-color: transparent;
    border: 2px solid transparent;
    border-left: none;
    border-bottom-right-radius: 3rem;
    border-top-right-radius: 3rem;

    .card-header,
    .card-body {
      padding: 0;
      border: none;
    }

    &--item {
      display: flex;
      align-items: center;
      padding-left: 25% !important;

      i {
        padding: 0.5rem;
      }
    }

    .nav-link {
      border: none !important;
      &:hover {
        background-color: rgba($color: white, $alpha: 0.1);
      }
    }

    &.open {
      background-color: rgba($color: white, $alpha: 0.1);
    }
    &.open:focus-within {
      border-color: var(--navlink-color-hover);
    }
  }
}

.mobile-sidebar-bg {
  display: none;
  @include mobile {
    display: block;
    z-index: 998;
    position: fixed;
    top: 1.5rem;
    left: 2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-image: radial-gradient(var(--secondary-blue), var(--primary-dark-blue));
    transition: 0.5s;
    &.show {
      transform: scale(45);
      overflow: hidden;
    }
  }
}
