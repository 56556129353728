.custom-pagination-component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 20px 0;
}
.custom-pagination-component .custom-pagination-component-page-item {
  background: rgba(31, 46, 79, 0.2078431373);
  font-size: 10px;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.custom-pagination-component .custom-pagination-component-page-item:hover {
  background: var(--primary-dark-blue);
  color: white;
  transition: all 0.4s ease-in-out;
}
.custom-pagination-component .page-item-active {
  background: var(--primary-dark-blue);
  color: white;
  transition: all 0.4s ease-in-out;
}
.custom-pagination-component p {
  color: lightgray;
}
.custom-pagination-component svg {
  cursor: pointer;
}