.insurance-policy-show-layout {
  &.main {
    display: grid;
    grid-template-columns: 3fr 3fr 1.5fr;
    gap: 3rem;

    @include tablet {
      grid-template-columns: auto;
      .side-components {
        display: grid;
        grid-template-columns: 2fr 2fr;
        gap: 3rem;
      }
    }

    @include mobile-sm {
      .side-components {
        grid-template-columns: auto;
      }
    }
  }

  &.summary-main {
    display: grid;
    grid-template-columns: 3fr 3fr;
    gap: 3rem;

    @include tablet {
      grid-template-columns: auto;
      .side-components {
        display: grid;
        grid-template-columns: 2fr 2fr;
        gap: 3rem;
      }
    }

    @include mobile-sm {
      .side-components {
        grid-template-columns: auto;
      }
    }
  }

  &.secondary {
    display: grid;
    grid-template-columns: 3fr 5.21fr;
    gap: 3rem;
    grid-template-rows: 30rem;

    @include tablet {
      grid-template-columns: auto;
    }
  }

  .general-information,
  .details-information,
  .side-components {
    .info-card,
    .statistic-card {
      margin-bottom: 3rem;
    }
  }
}

.commission-structure {
  p {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  dt {
    margin-bottom: 1rem;
    font-weight: 500;
  }

  dd {
    display: list-item;
    margin-left: 1.8rem;
  }
}

.accepted-info {
  padding: 0 1rem;

  p {
    padding-bottom: 0.5rem;
  }

  span {
    display: inline-block;
    font-weight: bold;
    width: 80px;
  }
}
