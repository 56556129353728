.landing {
  --navbar-height: 7rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include mobile {
    --navbar-height: 7rem;
  }
}

.landing-section-title {
  font-size: 4.6rem;

  @include tablet {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  @include mobile {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

// Navbar
.landing--navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  margin-right: 30px;

  &.broker {
    background-color: rgba($primary-dark-blue, 0.95);
    transition: 0.5s;
    backdrop-filter: blur(10px);
  }

  &.partner {
    background-color: rgba($primary-light-blue, 0.95);
    transition: 0.5s;
    backdrop-filter: blur(10px);
  }

  &.insured {
    background-color: rgba($secondary-blue, 0.95);
    transition: 0.5s;
    backdrop-filter: blur(10px);
  }

  &.company {
    background-color: var(--inline-primary-color);
    transition: 0.5s;
    backdrop-filter: blur(10px);
  }

  &.not-visible {
    transition: all 0.5s;
    background-color: transparent !important;
    backdrop-filter: none;
  }

  z-index: 100;

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__center {
    display: contents;

    & .link-text {
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      color: white;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $secondary-light-blue;
        cursor: pointer;
      }
    }
  }

  & .main-logo {
    width: 100% !important;
  }

  &__link {
    text-decoration: none;
    color: white !important;
    border-bottom: 3px solid transparent;
    font-size: 1.2rem;
    font-weight: 900;
    transition: 0.3s;
    margin: 0 2.5rem;
    margin-right: 20px;

    &:hover {
      text-decoration: none;
      color: $secondary-light-blue !important;
      cursor: pointer;
    }

    &.active {
      p {
        color: $secondary-light-blue !important;
      }
    }
  }

  @include tablet {
    padding: 0 4rem;
  }

  @include mobile {
    padding: 0 1rem;
    height: 6rem;
    & .main-logo {
      width: 100% !important;
    }
    &__link {
      display: none;
    }
    &__button {
      font-size: 1rem !important;
      padding: 0 1.5rem !important;

      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}

.btn-landing {
  padding: 18px 30px !important;
  border-radius: 100px !important;
  font-size: 12px !important;
  font-weight: 900 !important;

  &.inline-color {
    background-color: var(--inline-primary-color);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--inline-secondary-color) !important;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border-color: var(--inline-primary-color);
      box-shadow: 0 0 0 0.1rem var(--inline-primary-color);
    }
  }
}

.landing-button-broker {
  &:hover {
    background-color: $secondary-light-blue !important;
    color: var(--primary-dark-blue) !important;
  }
}

.landing-button-partner {
  background-color: transparent !important;
  border: 1px var(--primary-dark-blue) solid !important;
  color: var(--primary-dark-blue) !important;

  &:hover {
    background-color: var(--primary-dark-blue) !important;
    color: white !important;
    border: 1px white solid !important;
  }
}

.landing-button-insured {
  background-color: transparent !important;

  &:hover {
    background-color: var(--secondary-blue) !important;
    color: white !important;
  }
}

.w-120 {
  width: 120%;
  @include tablet {
    width: 100%;
  }
}

// Main
.landing--main {
  --main-color: transparent;
  background-color: var(--main-color);
  min-height: 70rem;
  max-height: 70rem;
  display: grid;
  grid-template-columns: 3fr 2fr;

  @include mobile {
    max-height: none;
  }

  // Variations client - broker
  &.client {
    --main-color: var(--secondary-blue);
    grid-template-columns: 1fr 1fr;

    @include mobile {
      height: 100vh;
      display: flex;
      justify-content: center;
    }

    .main-title {
      font-size: 7rem;
      @include mobile {
        font-size: 2.5rem;
      }
    }

    .main-subtitle {
      font-weight: 900;
      @include mobile {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 2rem !important;
      }
    }

    .main-text {
      max-width: 80%;
      font-weight: 500;
      color: white;
      margin-bottom: 5rem;
      @include mobile {
        font-size: 1.3rem;
        margin-bottom: 2rem;
      }
    }
  }

  &.broker {
    --main-color: var(--primary-dark-blue);

    @include tablet {
      grid-template-columns: 1.1fr 1fr;
    }

    @include mobile {
      background-color: var(--primary-dark-blue);
      height: 100vh;
      display: flex;
      justify-content: center;
    }
  }

  &__info {
    &-container {
      padding: 0 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
      background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
      background-repeat: no-repeat;
      background-size: contain;

      @include tablet {
        padding: 0 5rem;
        background-image: none;
      }

      @include mobile {
        background-image: none;
      }
    }

    & .main-title {
      color: white;
      font-size: 5rem;
      font-weight: 900;
      line-height: 4.5rem;
      margin-bottom: 2rem;
      @include show-hide();

      @include tablet {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    }

    & .main-subtitle {
      width: 80%;
      color: white;
      font-weight: 500;
      line-height: 2rem;
      margin-bottom: 3rem;
      @include show-hide();
    }
  }

  &__image {
    overflow-y: hidden;
    max-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 1;
    }
  }

  // Mobile
  @include mobile {
    display: flex;
    flex-direction: column;
    padding-top: var(--navbar-height);
    min-height: 38rem;
    &,
    &.client {
      --main-color: var(--secondary-blue) !important;
    }

    &__info {
      z-index: 2;
      padding: 0px;

      &-container {
        padding: 3rem;
        @include mobile {
          margin: auto;
        }
      }

      & .main-title {
        font-size: 2rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;

        @include mobile {
          font-size: 3rem;
          margin-bottom: 0px;
          line-height: 3.5rem;
        }
      }

      & .main-subtitle {
        width: 90%;
        line-height: 1.8rem;
        margin-bottom: 4rem;

        @include mobile-sm {
          font-size: 13px;
        }
      }

      & .main-button {
        display: block;
        margin: 0 auto;
      }
    }

    &__image {
      visibility: hidden;
      position: absolute;
    }
  }
}

// Why Nico
.landing--why-nico {
  display: grid;
  align-items: center;
  grid-template-columns: 40rem repeat(3, 25rem);
  grid-template-rows: 32rem;
  column-gap: 2rem;

  &__margin {
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 0px;
    }
  }

  .gg-copy {
    margin-top: -10px;
  }

  .gg-lock {
    margin-top: -25px;
  }

  .gg-mail-open {
    margin-top: 5px;
  }

  &__container {
    justify-content: center;
    padding: 10rem 10%;

    @include from-mid-screens {
      padding: 10rem 5%;
    }

    @include tablet {
      padding: 5rem 1.5rem;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &.client {
    grid-template-columns: 30rem repeat(2, 1fr);
    column-gap: 5%;
    grid-template-rows: unset;
  }

  &__tittlecontainer {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  &__title {
    max-width: 100rem;
    line-height: 4.3rem;
    text-align: center;
    font-size: 32px;
    @include mobile {
      line-height: 3.3rem;
    }
  }

  .why-nico--card {
    // border: 1px solid $primary-var-grey;
    padding: 3rem;
    border-radius: 4rem;
    height: 300px;
    width: 100%;
    background-color: #ccf9fd;

    @include tablet {
      height: 350px;
    }

    @include mobile {
      height: 100%;
    }

    &__icon {
      @include landing-icon-style();
    }

    &__title {
      margin-bottom: 1.5rem;
      line-height: 1.8rem;
      font-size: 22px;
      font-style: normal;
      font-weight: 900 !important;
      letter-spacing: 0.44px;
      text-transform: lowercase;
    }

    &__subtitle {
      margin-bottom: 1.5rem;
      line-height: 1.8rem;
      font-size: 14px;
      font-style: normal;
      font-weight: 800 !important;
      letter-spacing: 0.7px;
    }

    @include tablet {
      padding: 2.5rem;
    }
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    &-container {
      padding: 8rem 10% 3rem 10%;
    }

    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .why-nico--card {
      margin-bottom: 2rem;
    }
  }
}

// MultiLanding
.multilanding--main {
  --main-color: var(--primary-dark-blue);
  background-color: var(--main-color);
  height: 100vh;
  display: flex;

  @include mobile {
    height: auto;
  }

  @include tablet {
    grid-template-columns: 1.1fr 1fr;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    &-container {
      padding: 0 8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-height: inherit;
      background-image: url('./app/assets/images/Landing/BrokerLanding/index_info_container_desktop.png');
      background-repeat: no-repeat;
      background-size: cover;

      @include tablet {
        padding: 0 5rem;
        background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
      }

      @include mobile {
        background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
      }
    }

    & .main-title {
      color: var(--secondary-light-blue);
      font-size: 32px;
      font-weight: 900;
      line-height: 44px;
      margin-bottom: 2rem;
      text-align: center;

      @include show-hide();

      @include tablet {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    }

    & .main-subtitle {
      width: 80%;
      color: white;
      font-size: 22px;
      font-weight: 900px;
      line-height: 30px;
      margin-bottom: 3rem;
      text-align: center;
      @include show-hide();
    }
  }

  &__image {
    overflow-y: hidden;
    max-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 1;
    }
  }

  &__user-type {
    display: flex;
    flex-direction: row;
    padding: 0px 15rem;
    @include mobile-sm {
      padding: 0px 15px;
    }
    @include mid-screens {
      padding: 0px 5rem;
    }

    a {
      margin-bottom: 10px;
    }
  }

  // Mobile
  @include mobile {
    display: flex;
    flex-direction: column;
    padding-top: var(--navbar-height);
    min-height: 38rem;
    &,
    &.client {
      --main-color: var(--primary-dark-blue) !important;
    }

    &__info {
      z-index: 2;

      &-container {
        padding: 3rem;
      }

      & .main-title {
        font-size: 2rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;
      }

      & .main-subtitle {
        width: 90%;
        line-height: 1.8rem;
        margin-bottom: 4rem;
      }

      & .main-button {
        display: block;
        margin: 0 auto;
      }
    }

    &__image {
      visibility: hidden;
      position: absolute;
    }
  }

  .multilanding--card {
    &__nounderline {
      &:hover {
        text-decoration: none !important;
      }
    }

    border: 1px solid $primary-var-grey;
    padding: 5rem;
    border-radius: 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none !important;

    &:hover {
      border: 1.5px solid var(--secondary-light-blue);
    }

    &__bgicon {
      display: flex;
      width: 60px;
      height: 60px;
      background: $secondary-light-blue;
      align-items: center;
      justify-content: center;
      border-radius: 40px;

      i {
        color: $primary-dark-blue;
      }
    }

    &__icon {
      @include landing-icon-style();
    }

    &__title {
      color: white;
      margin-bottom: 1rem;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 135%; /* 29.7px */
      letter-spacing: 0.44px;
      text-decoration: none !important;
    }

    &__text {
      color: white;
      margin-bottom: 1rem;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      line-height: 150%; /* 19.5px */
      letter-spacing: 1.17px;
      text-decoration: none !important;
    }

    .multilanding--card__bgicon {
      transition: transform 0.3s ease, background-color 0.3s ease;
    }

    &__broker {
      .multilanding--card__bgicon {
        background: var(--secondary-light-blue) !important;
      }

      &:hover {
        .multilanding--card__bgicon {
          transform: scale(1.3);
        }

        border: 1.5px solid var(--secondary-light-blue) !important;
      }
    }

    &__partner {
      .multilanding--card__bgicon {
        background: var(--primary-light-blue) !important;
      }

      &:hover {
        .multilanding--card__bgicon {
          transform: scale(1.3);
        }

        border: 1.5px solid var(--primary-light-blue) !important;
      }
    }

    &__insured {
      .multilanding--card__bgicon {
        background: var(--secondary-blue) !important;
      }

      i {
        color: white;
      }

      &:hover {
        .multilanding--card__bgicon {
          transform: scale(1.3);
        }

        border: 1.5px solid var(--secondary-blue) !important;
      }
    }

    @include tablet {
      padding: 2.5rem;
    }
  }

  @include tablet {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mobile {
    &-container {
      padding: 8rem 10% 3rem 10%;
    }

    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .why-nico--card {
      margin-bottom: 2rem;
    }
  }
}

// Steps
.landing--steps {
  &-container {
    padding: 10rem 10%;
  }

  &__title {
    margin-bottom: 4rem;
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 35rem);
  row-gap: 2rem;

  .step {
    &-image {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-info {
      display: flex;
      align-items: center;

      &.client {
        align-self: center;
        display: grid;
        grid-template-columns: 5rem 1fr;
        row-gap: 1rem;

        .step-info--text-secondary {
          width: 80%;
          grid-column: 1 / -1;
        }
      }

      &--number {
        width: 4.5rem;
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--secondary-light-blue-30-light);
        background-color: var(--primary-dark-blue);
        border-radius: 50%;
        margin-right: 1rem;
        @extend h5;
      }
    }
  }

  @include mobile {
    &-container {
      padding: 3rem 1rem;
    }
    grid-template-columns: 1fr;
    grid-template-rows: unset;

    .step {
      &-info--text {
        font-size: 1.6rem;
      }

      &-1 {
        grid-row: 1 / 2;
      }

      &-3 {
        grid-row: 5 / 6;
      }
    }
  }
}

// Centered Content
.landing--centered-content {
  &-container {
    height: 45rem;
    position: relative;
    overflow: hidden;

    &.primary-var-light-grey {
      background-color: var(--primary-var-light-grey);
    }

    &.primary-var-light-blue {
      background-color: var(--primary-var-light-blue);
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;

  & > * {
    z-index: 99;
  }

  &__title,
  &__subtitle {
    font-weight: 900;
    line-height: 6rem;
    margin-bottom: 4rem;
    max-width: 80rem;
    text-align: center;
    width: 60%;
  }

  &__subtitle {
    margin: 1.5rem 0 0.5rem 0;
  }

  &__logo {
    text-align: center;

    img {
      width: 30%;
    }
  }

  &__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      align-items: normal;
    }

    img {
      min-width: inherit;
      min-height: inherit;
      object-fit: cover;
      object-position: center;
      opacity: 1;
    }
  }

  @include mobile {
    .btn {
      width: 80%;
    }

    &-container {
      height: 30rem;
    }
    &__title,
    &__subtitle {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      width: 90%;
    }

    &__logo {
      text-align: center;

      img {
        width: 40%;
      }
    }

    &__bg-image {
      height: 100%;

      img {
        min-height: 100%;
      }
    }
  }
}

// Footer
.landing--footer {
  display: grid;
  grid-template-columns: 1.6fr 1.5fr 1.5fr 2fr 1fr;

  .footer-link {
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--secondary-light-blue);
    }
  }

  .footer-button-company {
    background-color: var(--inline-secondary-color); /* Color para company */

    &:hover {
      background-color: var(--inline-secondary-color) !important;
    }
  }

  .dark-background {
    background: var(--primary-dark-blue);
    color: white !important;
  }

  &-container {
    padding: 7rem 6%;

    &.primary-dark-blue {
      background-color: var(--primary-dark-blue);
      background-image: url('./app/assets/images/Landing/BrokerLanding/broker_footer_container_desktop.png');
      background-repeat: no-repeat;
      background-size: contain;

      @include mobile {
        background-image: url('./app/assets/images/Landing/BrokerLanding/broker_footer_container_mobile.png');
      }
    }

    &.secondary-blue {
      background-color: var(--secondary-blue);
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
  }

  &__buttons {
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    margin-bottom: 1rem;

    &.company {
      i {
        color: var(--inline-secondary-color) !important;
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      width: 4rem;
    }

    &.company {
      img {
        filter: grayscale(1);
      }
    }
  }

  @include tablet {
    grid-template-columns: 1fr 1fr;

    .landing--footer__logo {
      margin-bottom: 30px;
    }
    .landing--footer__buttons {
      margin-bottom: 30px;
    }
    .landing--footer__contact {
      &-container {
        margin-bottom: 30px;
      }

      &.company {
        i {
          color: var(--inline-secondary-color) !important;
        }
      }
    }
    .landing--footer__socials-container {
      margin-bottom: 30px;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 3rem;

    &-container {
      padding: 3rem 10%;
    }
    & > * {
      margin-bottom: 3rem;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__contact > p {
      font-size: 1.2rem;
    }
    &__logo,
    &__socials-container {
      justify-self: flex-start;
      align-self: flex-start;
    }

    &__logo {
      margin-left: -2rem !important;
      margin-bottom: 1.5rem;
    }
    .developed-by-link {
      position: absolute;
      bottom: 0rem;
      left: 0;
      display: flex;
      width: 100%;
      justify-content: center;

      & > * {
        padding: 0 !important;
        opacity: 1;
      }
    }
  }
}

.landing--footer-broker {
  background: var(--primary-dark-blue); /* Color para broker */
}

.landing--footer-partner {
  background: var(--primary-light-blue); /* Color para partner */
}

.landing--footer-insured {
  background: var(--secondary-blue); /* Color para insured */
}

.landing--footer-company {
  background-color: var(--inline-primary-color); /* Color para company */
}

.landing--footer-text-dark {
  color: var(--primary-dark-blue) !important;
}

.landingMobileNavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  z-index: 1000 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .mobileMenuContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6); /* Fondo semitransparente para el efecto de fade */
    z-index: 999; /* Debe estar detrás del menú para poder hacer clic en el contenido cuando se cierra */
    visibility: hidden; /* Oculto por defecto */
    opacity: 0; /* Comienza totalmente transparente */
    transition: visibility 0s, opacity 0.5s linear; /* Transición suave para el efecto de fade */
    z-index: 22;
  }

  .opened {
    visibility: visible;
    opacity: 1; /* Totalmente visible cuando está abierto */
  }

  .closed {
    transition: visibility 0s 0.5s, opacity 0.5s linear; /* Retrasa la 'visibility' hasta que la opacidad llegue a 0 */
  }

  .mobileMenu {
    position: absolute;
    top: 0;
    left: -100vw; /* Empieza fuera de la pantalla */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // background: $primary-dark-blue; /* Tu color de fondo */
    transition: transform 0.5s ease; /* Transición suave para el efecto de deslizamiento */
    padding: 120px 10px 60px 10px;

    .mobileMenuItem {
      width: 100%;
      text-align: center;
      padding: 15px 0 10px 15px;
      border-bottom: 1px solid white;
      margin: 10px 0;
      font-size: 24px;
      font-weight: normal;
      color: white;
      text-align: start;

      p {
        font-size: 16px;
        font-weight: bold;
      }
    }

    // Segun tipos de rol
    .mobileMenuItem-broker {
      border-bottom: 1px solid white;
      color: white;
    }

    .mobileMenuItem-partner {
      border-bottom: 1px solid var(--primary-dark-blue);
      color: var(--primary-dark-blue);
    }

    .mobileMenuItem-insured {
      border-bottom: 1px solid white;
      color: white;
    }

    // Segun tipos de rol - active
    .mobileMenuItem-broker-active {
      border-bottom: 1px solid $secondary-light-blue;
      color: $secondary-light-blue;
    }

    .mobileMenuItem-partner-active {
      border-bottom: 1px solid white;
      color: white;
    }

    .mobileMenuItem-insured-active {
      border-bottom: 1px solid $secondary-light-blue;
      color: $secondary-light-blue;
    }

    // Menu Login Button
    .mobileMenuLogin {
      width: 100%;
      padding: 6vh 5vw 10px 5vw;
      margin: 10px 0;
      font-size: 24px;
      font-weight: normal;
      color: white;
    }
  }

  .opened .mobileMenu {
    transform: translateX(100vw); /* Desliza el menú a la vista */
  }

  .closed .mobileMenu {
    transform: translateX(0); /* Oculta el menú */
  }

  .mobileMenuButtons {
    z-index: 23 !important;
  }

  .mobileLogginBtn-broker {
    border: 1px solid var(--secondary-light-blue) !important;
    color: var(--primary-dark-blue) !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
    background: var(--secondary-light-blue) !important;
  }

  .mobileLogginBtn-partner {
    border: 1px solid var(--primary-dark-blue) !important;
    background: var(--primary-dark-blue) !important;
    color: white !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
  }

  .mobileLogginBtn-insured {
    border: 1px solid var(--secondary-light-blue) !important;
    color: var(--primary-dark-blue) !important;
    background: var(--secondary-light-blue) !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
  }

  .mobileMenuOpenBtn-broker {
    background-color: transparent !important;
    border: 1px solid white !important;
    color: white !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
  }

  .mobileMenuOpenBtn-partner {
    background-color: transparent !important;
    border: 1px solid var(--primary-dark-blue) !important;
    color: var(--primary-dark-blue) !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
  }

  .mobileMenuOpenBtn-insured {
    background-color: transparent !important;
    border: 1px solid var(--primary-light-blue) !important;
    color: white !important;
    min-width: 90px;
    padding: 0 10px !important;
    letter-spacing: 1px;
  }

  .text-primary {
    color: var(--primary-dark-blue) !important;
  }
}

.text-aqua {
  --icon-color: #64bfb0;
  color: #64bfb0;

  &:hover {
    color: #64bfb0 !important;
  }
}

.bg-aqua {
  background-color: #64bfb0;
}

.landingMobileNavbar-broker {
  background: var(--primary-dark-blue); /* Color para broker */
}

.landingMobileNavbar-partner {
  background: var(--primary-light-blue); /* Color para partner */
}

.landingMobileNavbar-insured {
  background: var(--secondary-blue); /* Color para insured */
}

.landingMobileNavbar-company {
  background: var(--inline-primary-color); /* Color para company */
}

.mobileMenu-broker {
  background: var(--primary-dark-blue); /* Color para broker */
}

.mobileMenu-partner {
  background: var(--primary-light-blue); /* Color para partner */
}

.mobileMenu-insured {
  background: var(--secondary-blue); /* Color para insured */
}

.mobileMenu-company {
  background: var(--inline-primary-color); /* Color para company */
}

.landing--navbar__link .partner-link .link-text {
  color: var(--primary-dark-blue);
}

.landing--navbar__link_partner {
  transition: 0.3s;

  p {
    color: var(--primary-dark-blue) !important;
  }

  &:hover,
  &:focus,
  &.active {
    color: white !important;

    p {
      color: white !important;
    }
  }
}

.login-btn-partner {
  background: var(--primary-dark-blue) !important;
  color: white !important;
  border: 1px solid white !important;

  a {
    color: white !important;
  }

  &:hover {
    background: #1f2e4fae !important;
  }
}

.company-landing {
  &__navbar-logo {
    object-fit: contain;
    max-height: 50px;
    max-width: 100px;
  }

  &__main {
    @extend .landing--main;
    padding: 10rem 10%;
    display: block;
    align-content: center;
    background-image: var(--inline-company-background);
    background-repeat: no-repeat;
    background-size: cover;

    @include mobile {
      background: var(--inline-primary-color);
    }

    .main-title {
      font-size: $h1-font-size;
    }

    .main-subtitle {
      font-size: $font-size-lg;
      display: ruby-text;
    }
  }
}

.white-logo {
  filter: brightness(0) invert(1);
}

.landing-benefits {
  padding: 15rem 10%;

  @include mobile {
    padding: 10rem 10%;
  }

  &__title {
    @extend p, .header-title;

    color: $body-color;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    column-gap: 2rem;

    @include mobile {
      display: block;
    }

    &__section {
      @extend .landing--why-nico, .landing--why-nico__content, .landing--why-nico, .landing--why-nico__margin;

      margin-bottom: 0;

      .why-nico--card {
        background-color: var(--inline-primary-color);

        &__title,
        &__subtitle,
        &__text {
          color: var(--inline-secondary-color) !important;
        }

        &__icon-container {
          background-color: var(--inline-secondary-color);

          i {
            color: var(--inline-primary-color) !important;
          }
        }
      }
    }
  }
}
