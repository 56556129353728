h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: clamp(3rem, calc(1rem + 5vw), 4.8rem);
  letter-spacing: calc(var(--project-font-size) * 3 * 0.02);
  line-height: calc(var(--project-font-size) * 3 * 1.35);
}

h2 {
  font-size: clamp(2.8rem, calc(1rem + 3vw), 4rem);

  letter-spacing: calc(var(--project-font-size) * 2.5 * 0.01);
  line-height: calc(var(--project-font-size) * 2.5 * 1.3);
}

h3 {
  font-size: clamp(2.4rem, calc(0.8rem + 2vw), 3.2rem);

  letter-spacing: calc(var(--project-font-size) * 2 * 0.03);
  line-height: calc(var(--project-font-size) * 2 * 1.3);
}

h4 {
  font-size: clamp(2rem, calc(0.8rem + 1vw), 2.9rem);

  letter-spacing: calc(var(--project-font-size) * 1.8 * 0.02);
  line-height: calc(var(--project-font-size) * 1.8 * 1.35);
}

h5 {
  font-size: clamp(1.8rem, calc(0.8rem + 1vw), 2.4rem);

  letter-spacing: calc(var(--project-font-size) * 1.5 * 0.02);
  line-height: calc(var(--project-font-size) * 1.5 * 1.35);
}

h6 {
  font-size: clamp(1.4rem, calc(0.5rem + 1vw), 1.8rem);

  letter-spacing: calc(var(--project-font-size) * 1.2 * 0.04);
  line-height: calc(var(--project-font-size) * 1.2 * 1.35);
}

p,
li {
  font-size: clamp(1rem, 1vw, 1.3rem);

  letter-spacing: calc(var(--project-font-size) * 0.8125 * 0.09);
  line-height: calc(var(--project-font-size) * 0.8125 * 1.5);
  margin: 0;
}

p.header-title {
  color: $primary-dark-blue;
  font-weight: 900;
  font-size: 2.2rem;
  line-height: 2.97rem;
  margin-bottom: 1rem;
}

p.row-title {
  font-size: clamp(1rem, calc(1rem + 1vw), 1.4rem);
  font-weight: bold;
  letter-spacing: calc(var(--project-font-size) * 0.625 * 0.05);
  line-height: calc(var(--project-font-size) * 0.625 * 1.35);
  margin-bottom: 1.5rem;
}

p.section-title {
  color: $primary-dark-blue;
  font-size: clamp(1rem, calc(1rem + 1vw), 1.4rem);
  font-weight: bold;
  letter-spacing: calc(var(--project-font-size) * 0.625 * 0.05);
  line-height: calc(var(--project-font-size) * 0.625 * 2);
  margin-bottom: 1.5rem;
}

p.section-subtitle {
  color: $secondary-grey;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  letter-spacing: calc(var(--project-font-size) * 0.625 * 0.05);
  margin: 1.5rem 0;
}

p.bullet-element {
  padding: 0.5rem 0;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  line-height: 2.4rem;

  // bullets
  &::before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $primary-light-blue;
    display: inline-block;
    margin-right: 1.5rem;
    padding: 0.5rem;
  }

  &.primary-dark-blue {
    &::before {
      background-color: $primary-dark-blue;
    }
  }
}

p.label {
  font-size: 1.1rem;
  margin: 0;

  span {
    letter-spacing: 0;
  }
}

p.detail {
  font-size: clamp(0.8rem, 1vw, 1rem);

  white-space: pre-line;
  letter-spacing: calc(var(--project-font-size) * 0.625 * 0.06);
  line-height: calc(var(--project-font-size) * 0.625 * 1.35);
  margin: 0;
}

p.option {
  letter-spacing: calc(var(--project-font-size) * 0.625 * 0.05);
}

.text-color-primary-dark {
  color: $primary-dark-blue !important;
}

.text-color-secondary {
  color: $secondary-blue !important;
}

.text-color-primary-light-blue {
  color: $primary-light-blue !important;
}

.text-color-secondary-light-blue {
  color: $secondary-light-blue !important;
}

.text-color-success {
  color: $green !important;
}

.text-color-warning {
  color: $warning-yellow !important;
}

.text-color-danger {
  color: $red !important;
}

.text-color-grey {
  color: $grey !important;
}

.text-color-white {
  color: $white !important;
}

.text-color-dark {
  color: $black !important;
}

.highlighted-text {
  background-color: lighten($primary, 40%);
  border-radius: 10rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  padding: 0.2rem 1rem;

  @include mobile {
    display: block;
  }
}
