.developed-by-nnodes {
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 2rem;
  transition: 0.3s;
  opacity: 0.7;

  &--text {
    color: white;
    font-size: 0.8rem;
    margin-right: 0.5rem;
    transition: inherit;
    opacity: inherit;
  }
  &--logo {
    display: block;
    width: 8rem;
    transition: inherit;
    opacity: inherit;
  }
  &:hover {
    opacity: 1;
  }
}
