.nested-attributes.row {
  display: flex;
  flex-direction: row;

  div.additional-nested-attributes:nth-child(2) {
    order: 3;
  }

  div.remove-btn:nth-child(3) {
    order: 2;
  }
}
