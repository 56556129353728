// CONTENT
:root {
  --sidebar-width: 25rem;
  --navbar-top-height: 8rem;
}

.content-body {
  --side-margin: 25rem;
  --content-side-padding: 10%;
  box-sizing: border-box;
  position: relative;
  width: calc(100% - var(--side-margin));
  margin-left: var(--side-margin);
  padding: 0 var(--content-side-padding);

  @include large-screens {
    --content-side-padding: 10%;
  }

  @include large-screens {
    --content-side-padding: 5%;
  }

  @include mid-screens {
    --content-side-padding: 2rem;
  }

  @include mobile {
    --content-side-padding: 2rem;
    --side-margin: 0;
  }
}
