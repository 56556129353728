.accordion-card {
  @extend .info-card;

  box-shadow: none;
  border: none !important;
  background-color: $primary-var-light-blue;
  margin-bottom: 0;
  padding: 1rem 2rem !important;

  &.with-overflow {
    overflow: visible !important;
  }

  .card-header {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    color: $gray-900;
    border: none !important;

    .btn-toggle-tree {
      background-color: transparent;
      border: none;
      color: $gray-900 !important;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
