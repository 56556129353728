.custom-breadcrumb {
  margin-top: 2rem;

  .breadcrumb {
    background-color: transparent;
    margin: 0 0 0.25rem 0;

    .breadcrumb-item {
      font-weight: bold;

      a {
        color: $grey;

        &:hover {
          color: lighten($grey, 10%);
          text-decoration: none;
        }
      }

      &.active,
      &::before {
        color: $primary-dark-blue;
      }
    }
  }
}
