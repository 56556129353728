.myagenda-index {
  .pending-summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    margin: 2rem 0 4rem 0;

    @include from-mid-screens {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 1rem;
    }

    @include mobile-sm {
      grid-template-columns: 1fr;
    }
  }
}
