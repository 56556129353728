@mixin standard-text {
  color: $primary-dark-blue;
  padding: 0 1.5rem 1rem 1.5rem;
}

.approval-container {
  @include custom-background-color($light-blue);

  .container {
    max-width: $lg;

    @include tablet {
      max-width: $md;
    }
  }

  h4,
  h6 {
    @include standard-text;
    margin-top: 2rem;
  }

  p {
    @include standard-text;
    font-weight: 500;

    span {
      font-weight: bold;
    }

    &.fine-print {
      margin-top: 2rem;
      font-weight: lighter;
    }
  }

  &__content {
    .buttons {
      margin-top: 2rem;
      padding: 0 1.5rem;

      display: grid;
      grid-template-areas: '. a b';
      grid-template-columns: 2.5fr 1fr 1fr;
      column-gap: 2rem;

      .show-quotation {
        grid-area: a;
      }

      .approve-quotation {
        grid-area: b;
      }

      @include tablet {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mobile {
        grid-template-areas: 'a a' 'b b';
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
      }
    }
  }

  &__response-message {
    margin-top: 3rem;
  }

  .background-circles {
    @include custom-background-circles;

    .circle-petite-1 {
      top: 10%;
    }

    .circle-petite-3 {
      top: 70%;
      left: 25%;
    }
  }
}
