// Colors
:root {
  --primary-dark-blue: #1f2e4f;
  --primary-light-blue: #21b0f2;
  --primary-var-grey: #bcc0ca;
  --primary-var-blue: #bce7fb;
  --primary-var-light-grey: #f4f5f6;
  --primary-var-light-blue: #f4fbfe;

  // Secondaries
  --secondary-blue: #1240d4;
  --secondary-light-blue: #29e5f5;
  --secondary-light-blue-30-light: #bff7fc;

  // Neutral
  --black: #232323;
  --soft-black: #353839;
  --grey: #919191;
  --light-grey: #e9e9e9;
  --white: #fff;

  // States
  --red: #b4002a;
  --green: #0da741;
  --green-submit: #17d859;
  --var-success: #d1f7de;
  --warning-yellow: #f8d73c;
  --var-red: #e6b3b3;
  --var-green: #b6e4c6;
  --var-warning-yellow: #fdf3c5;
  --danger: var(--red);
}

// Primaries
$primary-dark-blue: #1f2e4f;
$primary-light-blue: #21b0f2;
$primary-var-grey: #bcc0ca;
$primary-var-blue: #bce7fb;
$primary-var-light-grey: #f4f5f6;
$primary-var-light-blue: #f4fbfe;

// Secondaries
$secondary-blue: #1240d4;
$secondary-light-blue: #29e5f5;
$secondary-var-blue: #b8c6f2;
$secondary-light-blue-30-light: #bff7fc;
$secondary-grey: #adadad;

// Neutral
$black: #232323;
$grey: #919191;
$light-grey: #e9e9e9;
$white: $white;
$light-blue: #f4fefe;

// States
$red: #b4002a;
$green: #0da741;
$var-success: #d1f7de;
$green-submit: #17d859;
$warning-yellow: #f5b900;
$var-red: #faccd7;
$var-green: #d1f7de;
$var-warning-yellow: #fdf3c5;
$danger: $red !default;
