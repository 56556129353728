.account-show {
  &-layout {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 3rem;
    margin: 0;

    @include from-mid-screens {
      display: flex;
      flex-direction: column;
    }
  }

  &--general-info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  &-statistics {
    height: 100%;
    border-radius: 3rem;
    padding: 2rem 4rem;
    border: 1px solid $primary-var-grey;
    @include mobile-sm {
      max-height: unset !important;
    }

    &--tabs {
      min-height: 6.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      button {
        margin-bottom: 0.5rem;
      }
    }

    &--component {
      width: 100%;
      max-height: 35rem !important;
      @include mobile-sm {
        max-height: unset !important;
      }

      &.summary {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        @include mobile-sm {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
