.custom-upload-file {
  width: 100%;

  .custom-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: unset;
  }

  &--display {
    border-radius: 2rem;
    border: 1px solid $gray-400;
    flex: 1 1 60%;
    letter-spacing: normal;
    margin-right: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: auto !important;

    @extend .form-input;
    @extend p;
    @include standard-input();

    padding: 1.5rem;

    .custom-input--tooltip {
      top: 2.5rem;
    }

    &.custom-padding {
      padding-right: 4.5rem;
    }

    &.disabled {
      background-color: $light-grey;
    }
  }

  &--input {
    display: none;
  }

  &--label {
    height: 5rem;
    flex-shrink: 1;
    margin: 0;
    display: flex;
    align-items: center;
  }

  label.form-label {
    @include standard-label();
    transform: translate(2rem, -50%);
  }

  .is-invalid {
    border-color: $danger !important;
  }
}

// Dropzone

.dropzone {
  margin-bottom: 1rem;
  padding-top: 1.5rem;

  &__section {
    @include standard-input();
    border: 1px solid $gray-400;
    height: auto;
    min-height: 5rem;

    &.disabled {
      background-color: $light-grey;
    }

    &__add-files {
      text-align: center;
      padding: 2rem 0;

      p {
        color: $gray-600;
        font-weight: $font-weight-normal;
        letter-spacing: normal;
      }
    }

    &__box-files {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding: 1rem;

      &__file {
        align-items: center;
        display: inline-flex;
        margin: 0;

        &__icon {
          display: contents;
        }

        &__name {
          width: 50%;
          font-weight: 500;
        }
      }
    }
  }
}
