.carousel-container {
  width: 50rem;
  height: 50rem;
  border-radius: 3rem;
  overflow: hidden;
  margin-bottom: 3rem;
}
.carousel {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  &-caption {
    color: white;
  }
}

.info-card-carousel {
  .carousel {
    &-control-prev,
    &-control-next {
      width: 3.5rem;
    }

    &-control-prev {
      &-icon {
        background-image: str-replace(
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$primary-var-grey}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
          '#',
          '%23'
        );
      }
    }

    &-control-next {
      &-icon {
        background-image: str-replace(
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$primary-var-grey}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
          '#',
          '%23'
        );
      }
    }

    &-indicators {
      margin-bottom: 0.75rem;

      li {
        background-color: $primary-var-grey;
      }
    }

    &-inner {
      .carousel-item {
        img {
          object-fit: contain;
        }
      }
    }
  }

  .card {
    @include custom-info-card();

    &__content {
      min-height: 4rem;
      overflow: auto;
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
}
