.profile-avatar {
  display: flex;
  align-items: center;

  --profile-img-width: 15rem;

  .custom-file-img {
    width: var(--profile-img-width) !important;
    height: var(--profile-img-width) !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .custom-file-input {
    cursor: pointer !important;
  }

  .custom-file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3rem;
    right: auto;

    &::after {
      display: none;
    }
  }

  .input-group {
    width: inherit;
  }
}
