.rdt_TableRow {
  position: relative;
  padding: 0.7rem;
  align-items: center;

  &::before {
    // content: 'Póliza esta por vencer';
    content: '';
    position: absolute;
    border-radius: 100rem;
    bottom: -1.5rem;
    right: 1rem;
    font-weight: bold;
    padding: 0.25rem 1rem;
    transition: 0.3s;
    opacity: 0;
  }
  //like conditionals varians
  &.black {
    background-color: $primary-var-grey;
    &:hover {
      border: 1px solid $black;
    }
  }

  &.danger {
    background-color: $var-red;
    &:hover {
      border: 1px solid $red;
    }
  }

  &.warning {
    background-color: $var-warning-yellow;
    &:hover {
      border: 1px solid $yellow;
    }
  }
}

.custom-tooltip {
  &::after,
  &::before {
    border-left: transparent !important;
    border-right: transparent !important;
  }
  &.danger {
    background-color: $danger !important;
  }

  &.warning {
    background-color: $warning-yellow !important;
  }

  &.success {
    background-color: $success !important;
  }
}
