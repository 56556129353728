.__react_component_tooltip {
  padding: 0.5rem 1rem !important;
  border-radius: 1rem !important;
  &.show {
    z-index: 9999;
  }
}

.statistic-tooltip--container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.button-tooltip {
  padding: 1rem !important;
  @extend p;
  @extend .detail;
  @extend .font-weight-500;
  white-space: normal;
  line-height: 2rem;
}
