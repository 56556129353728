.table__insurance-covers {
  font-size: $font-size-base;

  thead {
    font-weight: $font-weight-bold;
  }

  tbody {
    font-weight: $font-weight-normal;
  }
}
