@mixin bullet($color) {
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $color;
}
.bar-chart {
  display: flex;
  align-items: center;
  justify-content: center;

  &--container {
    padding: 2.5rem;
    padding-bottom: 1rem;
    border-radius: 4rem;
    border: 1px solid var(--light-grey);

    &--title {
      display: flex;
      justify-content: space-between;
    }
  }

  .recharts-text,
  .recharts-legend-item-text {
    font-size: 1rem;
    color: var(--black);
  }

  // Tooltip
  &--tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-container {
      background-color: white;
      padding: 1rem;
      border: 1px solid var(--primary-dark-blue);
      border-radius: 0.5rem;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      &.normal::before {
        @include bullet(var(--primary-light-blue));
        margin-right: 0.5rem;
      }
      &.light::before {
        @include bullet(var(--primary-var-blue));
        margin-right: 0.5rem;
      }
      &.grey::before {
        @include bullet(var(--primary-var-grey));
        margin-right: 0.5rem;
      }
      &.dark-blue::before {
        @include bullet(var(--primary-dark-blue));
        margin-right: 0.5rem;
      }
    }
  }

  // Legend
  &--legend {
    &-container {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &--item {
      font-size: 1rem;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:first-child) {
        margin-left: 2rem;
      }
      &.normal::after {
        @include bullet(var(--primary-light-blue));
        margin-left: 0.5rem;
      }
      &.light::after {
        @include bullet(var(--primary-var-blue));
        margin-left: 0.5rem;
      }
      &.grey::after {
        @include bullet(var(--primary-var-grey));
        margin-left: 0.5rem;
      }
    }
  }
}
