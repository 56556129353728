.user-show-layout {
  &--main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin: 0;
    @include mid-screens {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
    @include mobile {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
  .statistics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
