.step-tabs {
  --background: transparent;
  --color: var(--secondary-blue);

  &--container {
    background-color: var(--primary-var-light-grey);
    padding: 0.7rem;
    box-shadow: inset 0px 2px 5px -1px #bcc0ca;
    @extend .rounded-border;
  }

  &--button {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: var(--background);
    color: var(--color);
    box-shadow: none;
    font-size: 1rem;
    min-height: unset;
    @extend p;
    @extend .font-weight-bold;

    &:active,
    &:focus,
    &:active &:active.active,
    &:focus.active,
    &:active.active {
      box-shadow: none !important;
      &:hover {
        --background: var(--secondary-blue);
      }
    }
    &:focus:hover {
      background-color: var(--background);
      color: white;
    }

    &.active {
      background-color: var(--secondary-blue) !important;
      color: white !important;
      // --background: var(--secondary-blue) !important;
      // --color: white;
      .step-tabs--button__step {
        --step-color: white;
      }
      .step-tabs--button__text {
        --step-color: white;
      }
    }

    &__step {
      --step-color: var(--secondary-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      flex-shrink: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      color: var(--step-color);
      border: 2px solid var(--step-color);
    }

    &__text {
      --step-color: var(--secondary-blue);
      color: var(--step-color);
      flex-shrink: 0;

      @include mobile-sm {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100vw / 3 - 8rem);
      }
    }

    &:hover {
      background-color: var(--background);
    }
  }
}
