.testimony-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 160px 15vw 160px 15vw;

  h3 {
    font-weight: 900;
    text-align: center;
    font-size: 32px;
    color: var(--primary-dark-blue);
  }

  .testimony-carousel {
    text-align: center;
    height: 350px;

    .testimony-item {
      padding: 20px;
        @include mobile {
          padding: 0px;
        }
    }

    .testimony-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
    }

    .testimony-img {
      width: 120px !important;
      height: 120px !important;
      border-radius: 50%;
      margin-bottom: 20px;
      border: 5px solid var(--secondary-light-blue);
    }

    .testimony-name {
      margin-bottom: 10px;
      font-size: 2rem;
      font-weight: bold;
    }
    .testimony-customer-type {
      letter-spacing: 0px;
      margin-bottom: 10px;
      font-size: 1.5rem;
      font-style: italic;
      font-weight: 500;
    }
    .testimony-text {
      font-style: italic;
      margin-bottom: 20px;
      font-size: 1.5rem;
    }

    .testimony-author h5 {
      font-size: 1.2rem;
      margin: 0;
    }

    .testimony-author p {
      font-size: 1rem;
      color: #6c757d;
      margin: 0;
    }
    .carousel-indicators li {
      opacity: 1;
      border: 1px solid var(--primary-dark-blue);
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
    .carousel-indicators .active {
      opacity: 1;
      border: 1px solid var(--primary-dark-blue);
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: var(--primary-dark-blue);
    }
  }

  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .testimony-section {
    .carousel {
      min-height: 420px;
    }
  }
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .testimony-section {
    padding: 60px 5vw;
    .carousel {
      min-height: 450px;
        @include from-mid-screens {
         min-height: 480px; 
        }
        @include mobile {
          min-height: 500px;
        }
    }
    h3 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .testimony-carousel .testimony-item .testimony-content {
      padding: 10px;
    }
    .testimony-img {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 15px;
    }
    .testimony-name {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }

    .testimony-customer-type {
      font-size: 1rem;
      margin-bottom: 5px;
    }

    .testimony-text {
      font-size: 1rem;
      padding: 0 10px;
    }
  }
}
