.landing-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding: 150px 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  .back-btn {
    position: absolute;
    top: 100px;
    left: 20px;

    span {
      color: white;
    }

    i {
      color: white;
    }

    &:hover {
      span,
      i {
        color: var(--secondary-light-blue);
      }
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 30px;
    border: 3px solid var(--secondary-light-blue);
    padding: 3rem;
    z-index: 100;
    width: 55%;
    min-height: 70vh;
    @media (max-width: 1024px) {
      width: 70%;
    }
    @media (max-width: 600px) {
      width: 95%;
    }
  }

  h6 {
    color: var(--primary-dark-blue);
    margin-top: 30px;
  }

  form {
    margin-top: 30px;
  }

  .btn-submit-broker {
    background: var(--primary-dark-blue);
    color: white !important;

    &:hover {
      background: #1f2e4fd0;
    }
  }

  .btn-submit-partner {
    background: var(--primary-light-blue);

    &:hover {
      background: #21b0f2d0;
    }
  }

  .btn-submit-insured {
    background: var(--secondary-blue);
    color: white !important;

    &:hover {
      background: #123fd4d0;
    }
  }
}

.landing-form-broker {
  background: var(--primary-dark-blue);
  background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
  @include tablet {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
  @include mobile {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
}

.landing-form-partner {
  background: var(--primary-light-blue); /* Color para partner */
  background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
  @include tablet {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
  @include mobile {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
}

.landing-form-insured {
  background: var(--secondary-blue); /* Color para insured */
  background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
  @include tablet {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
  @include mobile {
    background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_mobile.png');
  }
}

.landing-form-company {
  padding: 10rem 10%;
  background-color: var(--inline-secondary-color);
  display: block;

  &__title {
    @extend p, .header-title;

    color: $body-color;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__container {
    @extend .form-container;
    border-color: var(--inline-primary-color) !important;
    width: 80% !important;
    margin: auto;

    @include mobile {
      width: 100% !important;
    }
  }

  &__otic_container {
    @extend .form-container;
    border-color: var(--inline-primary-color) !important;
    width: 60% !important;
    margin: auto;

    @include mobile {
      width: 100% !important;
    }
  }
}
