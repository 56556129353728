.home-contact {
  @include custom-background-color($primary-dark-blue);

  .background-circles {
    @include custom-background-circles;

    .circle-petite-1,
    .circle-petite-2,
    .circle-petite-3 {
      background-color: $primary-light-blue;
    }

    .circle-top,
    .circle-left {
      background-color: #fff;
    }

    .circle-bottom {
      border-color: #fff;
    }

    @include tablet {
      .circle-petite-1 {
        top: 5%;
      }

      .circle-petite-2 {
        top: 30%;
        right: 3%;
      }

      .circle-petite-3 {
        top: 86%;
        left: 1%;
      }

      .circle-bottom {
        bottom: calc(var(--circle-width) / -1.8);
        right: calc(var(--circle-width) / -2);
      }
    }
  }

  &__navbar {
    @include public-navbar-position;

    &__back-btn {
      @include public-back-btn;
    }
  }

  &__form {
    background-color: #fff;
    border: 3px solid $secondary-light-blue;
    border-radius: 3rem;
    margin: 0 auto;
    padding: 5rem 3.5rem;

    @include tablet {
      margin: 0 10rem;
    }

    @include mobile {
      margin: 0 3.5rem 3.5rem 3.5rem;
    }

    &__logo {
      margin-bottom: 3rem;
      text-align: center;
    }

    &__title {
      margin-bottom: 3rem;
      text-align: center;

      h6 {
        color: $primary-dark-blue;
      }
    }
  }
}

.home-terms {
  background-color: $primary-dark-blue;
  box-sizing: border-box;
  left: 0;
  margin: 0 auto;
  padding: 10rem 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  .background-circles {
    @include custom-background-circles;

    .circle-petite-1,
    .circle-petite-2,
    .circle-petite-3 {
      background-color: $primary-light-blue;
    }

    .circle-top,
    .circle-left {
      background-color: #fff;
    }

    .circle-bottom {
      border-color: #fff;
    }

    @include tablet {
      .circle-petite-1 {
        top: 5%;
      }

      .circle-petite-2 {
        top: 30%;
        right: 3%;
      }

      .circle-petite-3 {
        top: 86%;
        left: 1%;
      }

      .circle-bottom {
        bottom: calc(var(--circle-width) / -1.8);
        right: calc(var(--circle-width) / -2);
      }
    }
  }

  &__navbar {
    @include public-navbar-position;

    &__back-btn {
      @include public-back-btn;
    }
  }

  &__form {
    background-color: #fff;
    border: 3px solid $secondary-light-blue;
    border-radius: 3rem;
    margin: 0 auto;
    padding: 5rem 3.5rem;

    @include tablet {
      margin: 0 10rem;
    }

    @include mobile {
      margin: 0 3.5rem 3.5rem 3.5rem;
    }

    &__logo {
      margin-bottom: 3rem;
      text-align: center;
    }

    &__title {
      margin-bottom: 3rem;
      text-align: center;

      h6 {
        color: $primary-dark-blue;
      }
    }
  }
}
