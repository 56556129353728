// Imports
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
@import './settings/index.scss';
@import './screens/index.scss';
@import './components/index.scss';
@import './shared/index.scss';

* {
  padding: 0;
  margin: 0;
  border: 0;
}

html {
  position: relative;
  min-height: 100%;
  font-size: 62.5%;
  /* Sets up the Base 10 stuff */
}

body {
  --project-font-size: 1.6rem;
  font-size: var(--project-font-size);
  padding-top: var(--navbar-top-height);

  &.no-scroll {
    position: sticky;
    overflow: hidden;
    overscroll-behavior: contain;
    margin-right: calc(100% - 100vw);
  }
}

.info-card.bg-children-inherit {
  div:not(.__react_component_tooltip) {
    background-color: inherit;
  }

  .form-label,
  .form-control {
    background-color: inherit;

    &:focus {
      background-color: inherit;
    }
  }
}

a {
  color: #1240d4;

  &:hover {
    color: #092a91;
  }
}

.success-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  border-radius: 30px;
  border: 3px solid var(--secondary-light-blue);
  padding: 3rem;
  width: 55%;
  min-height: 70vh;
  z-index: 100;

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 95%;
  }

  img {
    width: 200px;
    margin-bottom: 20px;
  }

  h6 {
    margin-bottom: 60px !important;
  }
}

// Clases del editor de Rich text
.demo-wrapper {
  min-height: 300px;
  border: 1px solid lightgray;
  border-radius: 20px;
  overflow: hidden;
  max-height: 500px;
}

.demo-editor {
  overflow: scroll;
  max-height: 400px;
  padding: 10px;
}

.insurance-company-demo-editor {
  max-height: 400px;
  padding: 10px;
}