.blog-section {
  .blog-header {
    min-height: 200px;
    padding: 5rem 10rem;
    display: flex;
    align-items: end;
    h1 {
      color: white;
    }
  }
  .blog-header-broker {
    background: var(--primary-dark-blue); /* Color para broker */
  }
  .blog-header-partner {
    background: var(--primary-light-blue); /* Color para partner */
    h1 {
      color: var(--primary-dark-blue);
    }
  }
  .blog-header-insured {
    background: var(--secondary-blue); /* Color para insured */
  }

  .fetured-blogs {
    padding: 5rem 25rem;
    h1 {
      margin-bottom: 2rem;
    }

    .featured-blogs-grid {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-gap: 3rem;
    }
  }

  .all-blogs {
    padding: 5rem 25rem 5rem 25rem;
    h1 {
      margin-bottom: 2rem;
    }

    .all-blogs-grid {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr;
      grid-gap: 6rem;
    }
    .blog-card {
      min-height: 180px !important;
    }
  }

  .blog-card {
    position: relative;
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 250px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: end;
    cursor: pointer;
    transition: background-color 0.5s ease;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      z-index: 1;
      transition: background-color 0.5s ease;
    }

    h6,
    p {
      position: relative;
      z-index: 2;
      color: white;
    }

    &:hover {
      &::before {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .published-date {
      position: absolute;
      top: 15px;
      right: 15px;
      color: white;
      z-index: 2;
      font-weight: lighter;
    }
  }

  .blog-show {
    padding: 5rem 15rem;
    h1 {
      margin-bottom: 2rem;
    }
  }

  .random-blogs {
    padding: 5rem 15rem 10rem 15rem;
    h1 {
      margin-bottom: 2rem;
    }

    .random-blogs-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem;
    }
  }

  .back-btn {
    position: relative;
    top: 20px;
    left: 20px;

    span {
      color: var(--primary-dark-blue);
    }

    i {
      color: var(--primary-dark-blue);
    }

    &:hover {
      span,
      i {
        color: var(--secondary-light-blue);
      }
    }
  }

  .all-blogs-title-and-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  /* Media query para tablets (por ejemplo, anchos de 768px a 1024px) */
  @media (max-width: 1024px) and (min-width: 768px) {
    .blog-header {
      padding: 5rem 3rem; /* Reducir el padding */
      min-height: 250px; /* Reducir la altura mínima */
    }

    .fetured-blogs,
    .all-blogs,
    .blog-show,
    .random-blogs {
      padding: 5rem;
    }

    .featured-blogs-grid,
    .all-blogs-grid,
    .random-blogs-grid {
      grid-template-columns: 1fr !important;
    }

    .blog-show-content img {
      width: 100%;
    }
  }

  /* Media query para móviles (por ejemplo, anchos menores de 768px) */
  @media (max-width: 767px) {
    .blog-header {
      padding: 2rem 1rem;
      min-height: 200px;
    }

    .fetured-blogs,
    .all-blogs,
    .blog-show,
    .random-blogs {
      padding: 2rem 1rem;
    }

    .featured-blogs-grid,
    .all-blogs-grid,
    .random-blogs-grid {
      grid-template-columns: 1fr !important;
    }

    .blog-show-content {
      padding: 0;
      align-items: stretch;
    }

    .back-btn {
      top: 10px;
      left: 10px;
    }

    .all-blogs-title-and-search {
      flex-direction: column;
    }
  }
}

.admin-blog-form {
  .custom-file-img {
    height: 350px !important;
    max-height: 350px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid lightgrey;
    border-radius: 15px;
    img {
      min-width: 100%;
    }
  }
}

.blog-show-content {
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 20px;
    font-weight: 900;
  }
  h6 {
    margin-bottom: 20px;
    font-weight: 900;
  }

  img {
    max-width: 100%;
    margin-bottom: 40px;
    border-radius: 30px;
  }

  .content {
    padding: 0 30px;
  }
}
