// Common mixins

@mixin box-shadow {
  box-shadow: 0px 4px 20px rgba(35, 35, 35, 0.3);
}

@mixin scrollbar($thumb-color: $primary-dark-blue) {
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: $gray-400;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 2.5px;
  }
}

@mixin custom-info-card {
  @extend .info-card;

  justify-content: normal;
  height: 100%;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    background-color: transparent;
    border: none;
  }

  .card-body {
    padding: 1rem 0;
  }
}

@mixin img-grayscale {
  object-position: center center;
  object-fit: contain;
  height: 100%;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);

  &:hover {
    -webkit-filter: grayscale(0);
    filter: none;
  }
}

// Landing

@mixin landing-icon-style {
  transform: scale(1.5);
  &-container {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    background-color: var(--primary-dark-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-shrink: 0;
  }
}

@mixin show-hide {
  opacity: 1;
  transition: 1s;
  &.hide {
    opacity: 0;
  }
}

@mixin public-back-btn {
  i {
    color: var(--primary-light-blue);
  }

  span {
    font-size: 1.6rem;
    font-weight: 900;
    margin-left: 1rem;
    color: #fff;

    &:hover {
      color: var(--primary-light-blue);
    }
  }
}

@mixin public-navbar-position {
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: space-between;
  left: 0;
  padding: 0 4rem 0 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

// Media Query Mixins
// 576px
@mixin mobile-sm {
  @media screen and (max-width: $sm) {
    @content;
  }
}
// 768px
@mixin mobile {
  @media screen and (max-width: $md) {
    @content;
  }
}

// 992px
@mixin tablet {
  @media screen and (max-width: $lg) {
    @content;
  }
}

// 1200px
@mixin from-mid-screens {
  @media screen and (max-width: $xl) {
    @content;
  }
}

// min 768px y 1200px
@mixin mid-screens {
  @media screen and (min-width: $md) and (max-width: $xl) {
    @content;
  }
}

@mixin large-screens {
  @media screen and (min-width: $xl) and (max-width: $xxl) {
    @content;
  }
}

@mixin xl-screens {
  @media screen and (min-width: $xxl) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $lg) {
    @content;
  }
}

// Background

@mixin custom-background-color($color) {
  background-color: $color;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  margin: 0 auto;
  padding: 10rem 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

@mixin standard-circle {
  position: absolute;
  border-radius: 50%;
  display: block;
  width: var(--circle-width);
  height: var(--circle-width);

  &.white {
    background-color: white;
  }
}

@mixin custom-background-circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 60rem;
  overflow: hidden;
  z-index: -1;

  @include mobile {
    display: none;
  }

  .circle-petite-1 {
    @include standard-circle;
    --circle-width: 2.5rem;
    background-color: var(--secondary-light-blue);
    top: 3%;
    right: 50%;
  }

  .circle-petite-2 {
    @include standard-circle;
    --circle-width: 5rem;
    background-color: var(--secondary-light-blue);
    top: 35%;
    right: 10rem;
  }

  .circle-petite-3 {
    @include standard-circle;
    --circle-width: 10rem;
    background-color: var(--secondary-light-blue);
    top: 80%;
    left: 10%;
  }

  .circle-top {
    @include standard-circle;
    --circle-width: 20rem;
    background-color: var(--secondary-blue);
    top: calc(var(--circle-width) / -2);
    right: calc(var(--circle-width) / -2);
  }

  .circle-left {
    @include standard-circle;
    --circle-width: 15rem;
    background-color: var(--secondary-blue);
    top: 20%;
    left: -10rem;
  }

  .circle-bottom {
    @include standard-circle;
    --circle-width: 25.6rem;
    bottom: calc(var(--circle-width) / -2);
    right: calc(var(--circle-width) / -3);
    border: 10px solid var(--secondary-blue);
  }
}

@mixin info-bg($color) {
  background-color: $color;
  border-radius: 2rem;
  font-weight: 500;
  padding: 1rem 1.5rem;

  p {
    letter-spacing: 0.05rem;
  }
}
