.contacts-form {
  position: relative;
}

.account-section {
  .section-title {
    margin-bottom: 2.5rem;
  }

  &__alert {
    display: flex;
    align-items: center;
    border-radius: 100rem;
    margin-bottom: 1rem;
    width: fit-content;
  }

  &__form {
    &__btn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1.5rem;

      button {
        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
