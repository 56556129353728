.register-request-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--icon-container {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    &.submit {
      background-color: var(--var-success);
    }
    &.warning {
      background-color: var(--var-warning-yellow);
    }

    i {
      transform: scale(3);
    }
  }
}
