.popup-menu {
  background-color: white;
  position: fixed;
  top: 8rem;
  right: calc(1.5rem + clamp(2rem, 5%, 5%));
  width: 25rem;
  border-radius: 2rem;
  padding: 2rem 0;
  border: 2px solid $secondary-blue;
  overflow: hidden;
  z-index: 99999;
  pointer-events: none;

  opacity: 0;
  transition: 0.3s;

  &.show-notifications {
    width: 40rem;
    padding-top: 0;
    right: calc(5.5rem + clamp(2rem, 5%, 5%));
  }

  &.show {
    pointer-events: visible;
    opacity: 1;
  }

  @include box-shadow();

  @include mobile {
    right: 1.5rem !important;
    max-width: calc(100vw - 3rem);
  }
}

.settings-menu {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .nav-link {
    color: $black;
    transition: 0.3s;
    padding-left: 2rem;
    border: 2px solid transparent;
    &:hover {
      color: $secondary-blue;
      background-color: rgba($color: $black, $alpha: 0.05);
    }
    &:focus-visible {
      border: 2px solid $secondary-blue;
      border-radius: 1rem;
      outline: none;
    }
  }
  .link-menu:focus-visible {
    outline: none;
  }
}
