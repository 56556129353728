// POSITION CLASSES

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

// Z-INDEX CLASSES

.z-3 {
  z-index: 3 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-n1 {
  z-index: -1 !important;
}
