.login-index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 10rem;
  background-color: var(--primary-dark-blue);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: hidden;
  @include mobile {
    display: flex;
    flex-direction: column;
    height: unset;
    padding-top: 7rem;
    min-height: 100vh;
  }
}

.login-catchphrase {
  width: 40rem;
  &--container {
    padding-top: 10rem;
    padding-left: 5rem;
  }
  &--text {
    padding-left: 2rem;
    line-height: 4rem;
  }

  // Mobile
  @include mobile {
    width: 100%;
    & .main-logo {
      width: 50%;
    }
    &--container {
      padding: 1rem;
      padding-top: 0;
      padding-bottom: 3rem;
    }
    &--text {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }
}

.login-box {
  z-index: 5;
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10rem;
    min-height: 70rem;
  }
  border: 2px solid var(--secondary-light-blue);
  border-radius: 3rem;
  padding: 5rem 3.5rem 2rem 3.5rem;
  width: 40rem;

  &--title {
    margin-bottom: 3rem;
  }
  &--form .form-input label {
    position: unset;
    background-color: transparent;
    color: white;
    @extend p;
    font-weight: 500;
    padding: 0;
    margin-left: -1rem;
  }
  &--forgot-password-link {
    color: white;
    font-weight: 700;
    @extend .section-title;
    transition: 0.3s;
    text-align: center;
    display: block;
    &:hover {
      text-decoration: none;
      color: var(--secondary-light-blue);
    }
  }

  // Mobile
  @include mobile {
    padding: 1rem;
    padding-top: 2rem;
    &--container {
      margin-top: 0rem;
      min-height: unset;
      padding: 2rem;
      padding-top: 0;
    }
    &--title {
      margin-bottom: 1rem;
    }
  }
}

.login-navbar {
  @include public-navbar-position;
  background-color: transparent;

  // Back Button
  &--back-btn {
    @include public-back-btn;
  }

  // Request Register button
  &--request-register-btn {
    background-color: transparent !important;
    border: 1px solid var(--primary-light-blue) !important;
    color: white;

    &:hover {
      color: var(--primary-light-blue) !important;
    }
  }

  // Mobile
  @include mobile {
    height: 7rem;
    padding: 0 1rem;

    &--back-btn {
      padding: 0 !important;
      &__text {
        font-size: 1.4rem;
        font-weight: 700;
      }
    }

    // Request Register button
    &--request-register-btn {
      font-size: 1.1rem !important;
    }
  }
}

.login-index--circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 60rem;
  z-index: 1;
  overflow: hidden;
  @include mobile {
    display: none;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
    display: block;
    width: var(--circle-width);
    height: var(--circle-width);
    &.white {
      background-color: white;
    }
  }
  .circle-big {
    --circle-width: 80rem;
    background-color: transparent;
    border: 60px solid white;
    top: -15rem;
    left: -20rem;
  }
  .circle-petite-1 {
    --circle-width: 1.8rem;
    bottom: 5rem;
    left: 20rem;
  }
  .circle-petite-2 {
    --circle-width: 2rem;
    top: 5rem;
    left: 55%;
  }
  .circle-petite-3 {
    --circle-width: 0.8rem;
    top: 50rem;
    right: 13rem;
  }
  .circle-bottom {
    --circle-width: 25.6rem;
    bottom: calc(var(--circle-width) / -2);
    right: calc(var(--circle-width) / -2);
  }
  .circle-colored {
    --circle-width: 10rem;
    bottom: 15%;
    left: 35%;
    background-color: var(--primary-light-blue);
  }
}
