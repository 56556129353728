.modal {
  &-content {
    border: none;
    box-shadow: 0 0 20px 0 $gray-700;
    border-radius: 3rem;
    padding: 2rem 3rem;
  }

  &-header {
    border: none;
  }

  &-footer {
    border: none;
  }

  &-xs.modal-dialog {
    min-width: 400px !important;
  }

  &-xxl {
    max-width: $modal-xxl;
  }
}

.fade.modal.show {
  z-index: 99999;
}

.fade.modal-backdrop {
  z-index: 99999;
}

.modal-body-icon {
  margin-bottom: 2rem;
  text-align: center;

  &__icon {
    margin-bottom: 3rem;
  }

  &__content {
    margin-bottom: 1rem;
  }

  &__sub-content {
    margin-bottom: 1.5rem;
    text-align: left;

    p {
      margin-bottom: 1rem;

      span {
        font-weight: 600;
      }
    }
  }

  &__confirm-content {
    font-weight: 500;
  }
}

.confirmation-modal-body {
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  &--icon-container {
    width: 10rem;
    height: 10rem;
    background-color: var(--var-warning-yellow);
    border-radius: 50%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      transform: scale(3);
    }
    margin-bottom: 2rem;
  }
}
