.custom-steps-map-container {
  position: relative;
  margin-bottom: 4rem;
}

.custom-steps-map {
  --step-color-filled: var(--primary-light-blue);
  --step-color: var(--primary-var-light-blue);

  display: flex;
  justify-content: space-between;

  &--line {
    position: absolute;
    border-color: var(--primary-light-blue);
    width: 100%;
    margin: 0;
    top: 3rem;
    z-index: -99;
  }

  &--step {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--step-color);
    border: 1px solid var(--step-color-filled);
    color: var(--step-color-filled);
    font-size: 2.5rem;
    padding: 0;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    box-shadow: none !important;

    &:hover {
      background-color: var(--step-color-filled);
    }
  }

  &--step.filled {
    background-color: var(--step-color-filled);
    color: white;
  }
}
