.icon-container {
  display: inline-block;

  --btn-width: 4rem;

  &.xs {
    --btn-width: calc(4rem * 0.7);
  }
  &.sm {
    --btn-width: calc(4rem * 0.8);
  }
  &.lg {
    --btn-width: calc(4rem * 1.2);
  }
  &.xl {
    --btn-width: calc(4rem * 1.3);
  }
  &.xxl {
    --btn-width: calc(3rem * 3.4);

    .only-icon {
      --btn-width: calc(3rem * 2.2);
    }
  }

  &.xs,
  &.sm,
  &.md,
  &.lg,
  &.xl,
  &.xxl {
    a,
    button,
    .only-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      width: var(--btn-width) !important;
      height: var(--btn-width) !important;
      border-radius: calc(var(--btn-width) / 2) !important;
      min-height: var(--btn-width) !important;
      i {
        margin-left: 0;
      }
    }
  }
}

.icon-size {
  --icon-size: 1;

  &-xs {
    --icon-size: 0.7;
  }
  &-sm {
    --icon-size: 0.8;
  }
  &-lg {
    --icon-size: 1.2;
  }
  &-xl {
    --icon-size: 1.3;
  }
  &-xxl {
    --icon-size: 3.2;
  }

  &-xs,
  &-sm,
  &-md,
  &-lg,
  &-xl,
  &-xxl {
    transform: scale(var(--icon-size));
  }
}

.gg-renewal-file {
  box-sizing: border-box;
  position: relative;
  display: block;
  box-shadow: -2px -2px 0 0, 2px 2px 0 0;
  width: 16px;
  height: 18px;
  border-radius: 3px;
  background: linear-gradient(to left, currentcolor 10px, transparent 0) no-repeat center 4px/10px 2px,
    linear-gradient(to left, currentcolor 10px, transparent 0) no-repeat center 8px/10px 2px,
    linear-gradient(to left, currentcolor 10px, transparent 0) no-repeat center 12px/10px 2px;

  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }

  &::before {
    border-left: 5px solid;
    top: -4px;
    right: 0;
  }

  &::after {
    border-right: 5px solid;
    bottom: -4px;
    left: 0;
  }
}

.gg-document-type {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--icon-size));
  width: 14px;
  height: 16px;
  border: 0 solid transparent;
  box-shadow: 0 0 0 2px;
  border-radius: 1px;
  border-top-right-radius: 4px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    background: currentColor;
    box-shadow: none;
    left: 0;
    width: 20px;
    height: 6px;
    top: 7.5px;
  }

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    right: -1px;
    top: -1px;
  }
}

.gg-document-type-thin {
  @extend .gg-document-type;

  box-shadow: 0 0 0 1px;

  &::after {
    border-left: 1px solid;
    border-bottom: 1px solid;
  }
}

.gg-trending {
  margin-top: 0.7rem !important;
  margin-right: 0.8rem !important;
  transform: rotate(-45deg) scale(var(--ggs, 1));
}

.gg-organisation {
  margin-right: 0.7rem !important;
}

.gg-home-alt {
  margin-top: 0.4rem !important;
}

.gg-play-list-add {
  margin-right: 0.4rem !important;
  margin-bottom: 0.2rem !important;
}

.gg-reorder {
  margin-bottom: 2rem !important;
}

.gg-heart {
  transform: translate(calc(-17px / 2 * var(--ggs, 1)), calc(-8px / 2 * var(--ggs, 1))) rotate(-45deg) scale(1.7) !important;
}

.gg-add-filled {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  border-radius: 22px;
  background-color: currentColor;

  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: $black;
    border-radius: 5px;
    top: 8px;
    left: 4px;
  }

  &::after {
    width: 2px;
    height: 10px;
    top: 4px;
    left: 8px;
  }
}
