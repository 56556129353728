@mixin standard-input {
  height: 5rem;
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  z-index: -1;
}

@mixin standard-label {
  letter-spacing: normal;
  position: absolute;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: white;
  color: $gray-800;
  padding: 0.25rem 1rem;
  z-index: 1;
  border-radius: 1rem;

  &.focused {
    color: $dark;
  }
}

@mixin font-options {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $body-color;
}

// Disabled inputs

.form-group {
  .css-1l8nyaj-control,
  .css-1tlwe76-control,
  .css-1w0i1vd-control {
    padding: 0.5rem 1.5rem;
    align-content: center;

    .css-g1d714-ValueContainer {
      padding-left: 0;
    }
  }

  .css-10now60-control,
  .css-1w0i1vd-control {
    background-color: $input-disabled-bg;
    border-color: $input-border-color;
    padding: 0.5rem 1.5rem;

    .css-g1d714-ValueContainer {
      padding-left: 0;

      .css-107lb6w-singleValue {
        color: $input-color;
      }
    }
  }

  &.is-invalid {
    .css-10now60-control,
    .css-1w0i1vd-control {
      border-color: $danger !important;
    }
  }
}

.css-1l8nyaj-control,
.css-1tlwe76-control,
.css-1w0i1vd-control {
  padding: 0.5rem 1.5rem;

  .css-g1d714-ValueContainer {
    padding-left: 0;
  }
}

// Inputs

.basic-multi-select {
  .css-b8ldur-Input div {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .css-1tlwe76 div::-webkit-scrollbar {
    display: none;
  }

  .css-1l8nyaj-control,
  .css-1tlwe76-control {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    align-content: baseline;
    padding: 0.3rem 0.5rem;

    .css-1rhbuit-multiValue,
    .react-select__multi-value {
      @include font-options();
      padding: 0.5rem;
      border-radius: 2rem !important;
      height: 2rem;
      align-items: center;
    }

    .react-select__indicators {
      align-self: flex-start;
      height: -webkit-fill-available;
    }
  }

  .react-select__option {
    input {
      accent-color: $primary-dark-blue;
    }
  }
}

.form-multi-email {
  @extend .form-input;

  .react-multi-email {
    border: $input-border-width solid $gray-400;
    padding: 1rem 1.5rem 0.8rem 1.5rem;
    border-radius: 2rem;
    min-height: 5rem;

    &.focused {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &.focused.is-invalid {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    &.is-invalid {
      border-color: $danger !important;
    }

    .data-labels > div {
      @include font-options();
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      border-radius: 2rem;
      height: 2.5rem;
    }
  }

  input {
    @include font-options();
    z-index: auto !important;
    height: 3rem !important;
  }
}

.form-input {
  box-sizing: border-box;
  padding-top: 1.5rem;
  position: relative;

  input {
    @include standard-input();
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
  }

  label {
    @include standard-label();
    transform: translate(2rem, -50%);
  }

  .custom-input--tooltip {
    position: absolute;
    top: 4rem;
    right: 1rem;
    transform: translateY(-50%);
  }
}

.form-simple-input {
  margin-bottom: 0;

  .is-invalid:focus {
    box-shadow: 0 0.2rem 0 rgba(220, 53, 69, 0.25);
  }

  &.custom-width {
    width: 75%;

    @include desktop {
      width: 50%;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid $gray-400;
    border-radius: 0;
    display: block;
    height: 4rem;
    overflow: hidden;
    padding: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:disabled {
      border-radius: 2rem;
      padding: 0.5rem 1.5rem;
      height: 5rem;
    }

    &:focus {
      box-shadow: 0 0.2rem 0 rgba(0, 123, 255, 0.25);
    }
  }
}

.form-textarea-input {
  box-sizing: border-box;
  padding-top: 1.5rem;
  position: relative;

  &.is-invalid {
    textarea {
      border-color: $danger !important;
    }
  }

  textarea {
    @include standard-input();
    padding: 1.25rem 1.75rem !important;
    height: auto;
    z-index: 1;

    &.with-tooltip {
      padding-right: 4.5rem !important;
    }

    &:focus {
      z-index: -1 !important;
    }
  }

  label {
    @include standard-label();
    transform: translate(2rem, -50%);
  }

  .custom-textarea--tooltip {
    position: absolute;
    z-index: 9;
    top: 2.5rem;
    right: 1rem;
  }
}

.form-numeric-input {
  box-sizing: border-box;
  padding-top: 1.5rem;

  input {
    @include standard-input();
    z-index: 1;

    &:focus {
      z-index: 1 !important;
    }
  }

  label {
    @include standard-label();
    transform: translate(2rem, -50%);
    z-index: 2 !important;
  }
}

// Select
.form-select {
  padding-top: 1.5rem;

  label {
    @include standard-label();
    transform: translate(2rem, -50%);
  }

  // &.with-tooltip {
  //   .css-1hb7zxy-IndicatorsContainer {
  //     padding-right: 2rem;
  //   }
  //   .css-1wy0on6 {
  //     padding-right: 2rem;
  //   }
  // }
}

// // Select option disabled

.css-vo2jma-option {
  color: $light-grey !important;
}

// Checkbox
.form-checkbox-switch {
  --form-checkbox-container-height: 5rem;
  --checkbox-height: 3rem;

  &.switch-sm {
    --form-checkbox-container-height: 3rem;
    --checkbox-height: 2rem;
  }

  width: calc(var(--checkbox-height) * 3);
  height: var(--form-checkbox-container-height);
  display: flex;
  align-items: center;
  margin: 0;

  .custom-control-label {
    display: none;
  }

  .custom-switch-label {
    text-indent: -9999px;
    width: calc(var(--checkbox-height) * 2);
    height: var(--checkbox-height);
    background: $primary-var-grey;
    display: block;
    border-radius: 100px;
    position: relative;
    transform: translateX(-1.5rem);
    transition: 0.3s;
  }

  .custom-switch-label:after {
    content: '';
    position: absolute;
    top: calc(var(--checkbox-height) * 0.1);
    left: calc(var(--checkbox-height) * 0.1);
    width: calc(var(--checkbox-height) * 0.8);
    height: calc(var(--checkbox-height) * 0.8);
    background: #fff;
    border-radius: calc(var(--checkbox-height) * 0.8);
    transition: 0.3s;
  }

  .custom-switch-label.checked {
    background: $primary-dark-blue;
    // Variations
    &.danger {
      background: var(--red);
    }
    &.disabled {
      background: var(--primary-var-grey);
    }
  }

  .custom-switch-label.checked::after {
    left: calc(100% - calc(var(--checkbox-height) * 0.1));
    transform: translateX(-100%);
  }

  .custom-switch-label.active::after {
    width: calc(var(--checkbox-height) * 1.3);
  }
}

.form-checkbox {
  --form-checkbox-container-height: 5rem;
  --checkbox-width: 2rem;
  --checkbox-color: var(--primary-light-blue);

  height: var(--form-checkbox-container-height);
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;

  .custom-control-label {
    &::before {
      display: none;
    }
  }

  .form-label {
    display: flex;
    align-items: center;

    &::before {
      display: inline-block;
      border: 2px solid var(--checkbox-color);
      border-radius: 3px;
      width: var(--checkbox-width);
      height: var(--checkbox-width);
      position: unset;
      margin-right: 0.75rem;
      content: '';
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 2rem;
    }

    &.checked::before {
      background-color: var(--checkbox-color);
      content: '\2713';
      color: white;
    }
  }
}

.form-radio-input {
  --form-checkbox-container-height: 3rem;
  --radio-width: 2rem;

  height: var(--form-checkbox-container-height);
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;

  .form-check-input {
    display: none;
  }

  .form-check-label {
    @extend p;
    @extend .font-weight-500;
    margin-right: 2rem;
    background-color: transparent;
  }

  .custom-radio-label {
    border: 2px solid var(--primary-light-blue);
    width: var(--radio-width);
    height: var(--radio-width);
    border-radius: 50%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .disabled {
    border: 2px solid var(--primary-var-grey);
  }

  .custom-radio-label.checked::before {
    display: flex;
    content: '';
    width: calc(var(--radio-width) / 2);
    height: calc(var(--radio-width) / 2);
    background-color: var(--primary-light-blue);
    border-radius: 50%;
  }

  .custom-radio-label.checked.disabled::before {
    background-color: var(--primary-var-grey);
  }
}

// Datepicker
.form-datepicker {
  height: 100%;
  padding-top: 1.5rem;

  input {
    @include standard-input();
  }

  label {
    @include standard-label();
    transform: translate(2rem, -50%);
  }

  @extend .datepicker;
}

// INVALID
.is-invalid {
  .css-1l8nyaj-control {
    border-color: $danger !important;
  }

  &.form-numeric-input,
  &.form-numeric-input:focus {
    .form-control {
      border-color: $danger;
    }
  }
}

.form-radio {
  label {
    background-color: white;
    border: 1px solid $secondary-light-blue;
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 25px;
    display: block;
    padding: 5px 25px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: rgba($secondary-light-blue, 0.2);
    }
  }

  input:checked + label {
    background-color: rgba($secondary-light-blue, 0.2);
  }
}

.input-group {
  & :not(:last-child) {
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }

  .custom-input--tooltip {
    position: absolute;
    z-index: 9;
    top: 1.25rem;
    right: 10px;
  }
}

.checkbox-group-label {
  @include standard-label();
  top: -1rem;
  left: 2.5rem;
}

.input-buttons {
  display: flex;
  flex-direction: column;

  label {
    @include standard-label();
    margin-top: 0.5rem;
    position: relative;
  }

  &__options > button {
    margin-bottom: 1rem;
    min-height: 3.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.formik-switch {
  margin-bottom: 3rem;

  label {
    @include standard-label();
    margin: 0;
    padding: 0;
    top: 0.75rem;
  }

  .form-group {
    position: relative;
    top: 1.5rem;
  }

  .custom-input--tooltip {
    position: relative;
    top: 0.6rem;
  }
}

.formik-input-radio {
  margin-bottom: 1rem;

  label {
    @include standard-label();
    position: relative;
    margin: 0;
    padding: 0;
    top: 0.75rem;
  }

  .custom-input--tooltip {
    position: relative;
    top: 0.5rem;
  }
}

.label-title {
  @include standard-label();
  transform: translate(2rem, -50%);
}

.label-title-outside {
  @include standard-label();
  letter-spacing: normal;
  margin-top: 0.5rem;
  position: relative;
}

.optional-label {
  color: $grey;
  font-weight: 500;
  letter-spacing: 0.06em;
}

.textarea-tab-size {
  tab-size: 46;
}

.textarea-no-resize {
  resize: none;
}

.textarea-horizontal-resize {
  resize: horizontal;
}

.textarea-vertical-resize {
  resize: vertical;
}

.textarea-both-resize {
  resize: both;
}

// Data Table Inputs

.modal-body {
  .datatable-textarea {
    @extend .form-textarea-input;
    padding: 0;

    textarea {
      &:focus {
        z-index: 1 !important;
      }
    }
  }
}

.rdt_Table {
  --col-padding: 0 1rem;

  .rdt_TableCol {
    padding: var(--col-padding);
  }

  .rdt_TableCell {
    padding: var(--col-padding);

    .datatable-input {
      @extend .form-numeric-input;
      padding: 0;
    }

    .background-yellow {
      @extend .form-numeric-input;
      background-color: $var-warning-yellow;
    }

    .datatable-textarea {
      @extend .form-textarea-input;
      padding: 0;

      textarea {
        min-height: 5rem;
        height: 5rem;

        &:focus {
          z-index: 1 !important;
        }
      }
    }

    .datatable-datepicker {
      @extend .form-datepicker;
      box-sizing: border-box;
      padding: 0;

      .react-datepicker__header,
      .react-datepicker__day {
        border-radius: 0.3rem !important;
      }
    }
  }
}

// input tooltip

.form-select,
.form-datepicker {
  .custom-input--tooltip {
    position: absolute;
    top: 2.6rem;
    right: 2.5rem;
  }
}
