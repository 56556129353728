.notification-button {
  position: relative;
  &--badge {
    position: absolute;
    background-color: var(--red);
    color: white;
    font-size: 1.2rem;
    font-weight: 900;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 0;
    right: -15%;
    pointer-events: none;
    cursor: pointer;
  }
}
