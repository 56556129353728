.home-index {
  padding: 2rem 0;

  &--header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin-bottom: 3rem;
    @include from-mid-screens {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }

  &--statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;
    @include from-mid-screens {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  &--charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin-bottom: 3rem;
    @include from-mid-screens {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
}

.insured-home-index {
  @extend .home-index;

  margin-bottom: 5rem;

  .section {
    &__info-card-carousel {
      max-height: 27rem;
    }

    &__info-card-insurance-companies {
      .card {
        @include custom-info-card();

        .insurance-company-logo {
          column-gap: 13rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          &__container {
            margin-top: 3rem;

            &__image {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100px;

              img {
                @include img-grayscale();
                width: 120px;
              }
            }

            &__button {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}
