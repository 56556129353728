.datatable-task-component {
  &--button {
    transition: 0.3s;
    &.show {
      background-color: var(--secondary-light-blue-30-light);
      border-radius: 1rem !important;
      padding: 0 0.75rem !important;
      &:active,
      &:focus {
        background-color: var(--secondary-light-blue-30-light) !important;
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }

  &--show {
    margin-top: -2rem;
    margin-bottom: 2rem;
    &__icon-container {
      width: 10rem;
      height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--secondary-light-blue-30-light);
      border-radius: 100rem;
      margin-bottom: 2rem;
    }
    &__icon {
      transform: scale(3);
    }
  }
}
