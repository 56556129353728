.pending-summary--modal-show {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-var-grey);
    }
  }
}
