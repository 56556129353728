.btn-badge-icon {
  --icon-color: white;
  --background-main-color: var(--primary);
  background-color: var(--background-main-color) !important;
  color: var(--icon-color) !important;
  box-shadow: none !important;
  --btn-height: 24px !important;
  width: 24px;

  transform: scale(0.7);

  &:hover {
    color: var(--icon-color);
    background-color: var(--background-main-color);
    border: 2px solid $white;
  }

  &.dark {
    --background-main-color: var(--dark);
  }

  &.danger {
    --background-main-color: var(--danger);
  }

  &.warning {
    --background-main-color: var(--warning-yellow);
    --icon-color: var(--black);
  }

  &.success {
    --background-main-color: var(--success);
  }

  &.primary-light {
    --background-main-color: var(--primary-light-blue);
  }

  &.primary-dark {
    --background-main-color: var(--primary-dark-blue);
    --icon-color: var(--primary-light-blue);
  }

  &.grey {
    --background-main-color: var(--grey);
  }
}

.tab-filter-badge {
  @extend .notification-button--badge;
  position: relative !important;
  right: -5% !important;
}
