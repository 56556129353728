.navbar-top {
  --background-main-color: white;
  --links-background-color: #1240d4;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-top-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  background-color: var(--background-main-color);
  transition: 0.3s;

  &.admin {
    --links-background-color: #1f2e4f;
    .navbar-top--logo {
      width: var(--sidebar-width) !important;
      border-bottom-right-radius: 0;
      justify-content: flex-start;
    }
  }

  &.sidebar-active {
    background-color: transparent;
  }

  &--logo {
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--links-background-color);
    display: flex;
    align-items: center;
    padding-left: 3.5rem;
    .main-logo {
      width: 100%;
    }
    @include mobile {
      display: none;
    }
  }

  &--right {
    display: flex;
    padding-right: clamp(2rem, 5%, 5%);
  }

  .navbar-top--button {
    margin-left: 1rem;
  }

  .mobile-menu {
    display: none;
    @include mobile {
      margin-left: 0;
      display: block;
      padding-left: 2rem;
    }
  }
}

.main-logo {
  display: block;
  width: calc(13.5rem * 1.5);
}

.cover-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s;
  z-index: 0;
  display: none;

  &.show {
    opacity: 1;
    z-index: 99998;
    display: block;
  }
}
