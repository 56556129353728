.historical-record {
  &__scrollable {
    max-height: 18rem;
    padding-right: 2rem;
    overflow-y: auto;

    @include scrollbar();

    &.custom-height {
      max-height: 32rem;
    }
  }

  &--empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29.5rem;
    overflow-y: hidden !important;
  }

  &.with-title {
    border-radius: 3rem;
    border: 1px solid var(--primary-var-grey) !important;
    padding: 2rem 4rem;
  }

  &__title {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
    min-height: 4rem;
  }

  &--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--primary-var-grey);
    padding: 1.5rem 2.5rem;

    &__text {
      flex: 1 1 auto;
      word-wrap: break-word;
      overflow-x: hidden;
    }
    &__date {
      flex: 0 0 auto;
      margin-left: 1rem;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
