.datepicker {
  .react-datepicker {
    font-size: 1.2rem;
    font-family: 'Montserrat';
    z-index: 2 !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: $primary-var-grey;
    padding-top: 0.8em;

    .react-datepicker__current-month {
      text-transform: capitalize;
    }
  }

  .react-datepicker__navigation {
    &--previous {
      border-right-color: $primary-dark-blue !important;
    }

    &--next {
      border-left-color: $primary-dark-blue !important;
    }
  }

  .react-datepicker-popper {
    z-index: 11 !important;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day--outside-month {
    color: #e0e0e0 !important;
    pointer-events: none;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
    border-radius: 0.3rem !important;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
}
