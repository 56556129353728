.quotation-select {
  &__container {
    // cursor: pointer;
    // align-items: center;
    // display: flex;
    // overflow-x: hidden;

    // .list-element {
    //   // Sobrescribe los estilos de los bullets para no mostrarlos
    //   &::before {
    //     content: none !important;
    //   }
    // }

    .profile-avatar {
      display: flex;
      align-items: center;

      --profile-img-width: 7rem;
    }
  }

  &__selected {
    border: 1px solid $primary-var-grey;
    background-color: white !important;
    border-radius: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__request-approval {
    align-self: center;
    margin-top: 1rem;
    text-align: center;

    @include mobile {
      button {
        width: 100% !important;
        margin: 1.5rem 0;
      }
    }
  }
}

.lead-show {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 3rem;

  &__second-column {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;

    @include tablet {
      grid-template-columns: auto;
    }

    .info-card {
      min-height: 17rem;
    }
  }
}
