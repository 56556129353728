a.custom-link {
  font-size: 1.3rem;
  transition: 0.3s;
  position: relative;
  color: var(--secondary-blue);
  padding: 0.5rem 0.8rem;
  border-radius: 100rem;
  border: 1px solid transparent;
  font-weight: normal;

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: var(--primary-var-light-blue);
    border-color: var(--secondary-light-blue-30-light);
  }
}

a.custom-icon-btn,
button.custom-icon-btn {
  background-color: transparent;

  i {
    color: var(--secondary-blue);
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
}
