// Main
.client-landing--main {
  background-color: var(--secondary-blue);
  min-height: 80rem;
  max-height: 80rem;
  display: grid;
  grid-template-columns: 3fr 2fr;

  &__success_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    min-height: 400px;
    padding: 20px 7%;

    letter-spacing: 0.58px;
  }
  &__modal-span {
    font-size: 21px;
    font-style: normal;
    font-weight: 900;
    line-height: 115%;
    letter-spacing: 0.58px;
    color: var(--secondary-blue);
  }
  &__modal-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 115%;
    letter-spacing: 0.58px;
    margin: 10px 0;
  }

  &__heroclientsplans {
    background-color: $secondary-blue;
    min-height: 70rem;
    max-height: 70rem;
    display: grid;

    @include mobile {
      padding: 8rem 0rem;
      max-height: none;
    }

    @include mid-screens {
      padding: 5rem 2rem;
      max-height: none;
    }

    &__subtitle {
      color: white;
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 135% !important;
      letter-spacing: 0.7px !important;
      margin-bottom: 0px !important;
    }

    &__card {
      padding: 20px;
      @include mobile {
        border-radius: 50px;
        padding: 10px 15px 10px 12px !important;
      }
      p {
        color: $primary-dark-blue !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 135% !important;
        letter-spacing: 0.7px !important;
        margin-bottom: 0px !important;
      }

      &__container {
        padding: 10px 5px;
        border-radius: 100px;
        // border: 2px solid $secondary-light-blue;
        background: white;
        margin-bottom: 15px;
        @include mobile-sm {
          padding: 5px 0px !important;
        }

        &__iconcontainer {
          width: 65px;
          height: 65px;
          margin-right: -10px;
          background: $secondary-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          @include mobile {
            width: 40px;
            height: 40px;
          }
          @include mid-screens {
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    &__container {
      padding: 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
      background-repeat: no-repeat;
      background-size: cover;

      @include tablet {
        padding: 3rem;
        background-image: none !important;
      }

      @include mobile {
        display: flow;
        background-image: none !important;
      }

      h1 {
        color: white;
        font-size: 58px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%; /* 59.8px */
        letter-spacing: 0.92px;

        @include mobile {
          font-size: 50px;
        }
        @include mobile-sm {
          font-size: 40px;
        }
      }

      p {
        color: white;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        margin-bottom: 15px;
      }

      &__main {
        background-image: url('./app/assets/images/Landing/ClientLanding/insured_info_container_desktop.webp') !important;
        @include tablet {
          background-image: none !important;
        }

        @include mobile {
          background-image: none !important;
        }
      }

      &__inner {
        background-image: url('./app/assets/images/Landing/ClientLanding/insured_inner_info_container_desktop.webp') !important;
        @include tablet {
          background-image: none !important;
        }

        @include mobile {
          background-image: none !important;
        }
      }
    }
  }

  &__info {
    &-container {
      padding: 0 3rem 0 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
    }

    &__main-title {
      color: white;
      font-size: 3.625em;
      font-style: normal;
      font-weight: 900;
      line-height: 115%;
      letter-spacing: 0.58px;
      margin-bottom: 15px;
      @include from-mid-screens {
        font-size: 2.625em;
      }
    }

    &__main-subtitle {
      color: white;
      font-size: 1.25em;
      font-style: normal;
      font-weight: 900;
      line-height: 115%;
      letter-spacing: 0.72px;
      margin-bottom: 15px;
    }

    &__main-text {
      color: white;
      font-size: 0.938em;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 19.5px */
      letter-spacing: 1.17px;
      margin-bottom: 30px;
    }

    &__formclient {
      padding: 10rem 10rem 20rem 10rem;
      @include tablet {
        padding: 3rem 2rem 9rem 2rem;
      }
      .icon-btn {
        @include mobile {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      &__title {
        font-size: 1.8em;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 29.7px */
        letter-spacing: 0.44px;
        margin-bottom: 10px;
        text-align: left;
        color: $secondary-blue;

        @include tablet {
          text-align: left;
          font-size: 1.125em;
        }
        @include mobile {
          text-align: center;
          font-size: 1.125em;
          margin-bottom: 20px;
          margin-top: 5%;
        }
      }

      &__subtitle {
        font-size: 1.125em;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 24.3px */
        letter-spacing: 0.72px;
        margin-bottom: 10px;

        @include tablet {
          text-align: left;
          font-size: 0.813em;
        }
        @include mobile {
          text-align: center;
          margin-bottom: 20px;
          margin-top: 5%;
        }
      }

      &__text {
        font-size: 0.813em;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        @include mobile {
          text-align: center;
        }
      }

      &__containerform {
        padding: 30px;
        border-radius: 40px;
        border: 1px solid $primary-var-grey;
        background: $light-blue;
        min-height: 250px;
        @include mobile {
          margin-top: 30px;
        }

        &__easytext {
          color: var(--Neutros-Gris-oscuro, #919191);
          font-family: Montserrat;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 19.5px */
          letter-spacing: 1.17px;
          @include mobile {
            text-align: center;
          }
        }
      }
    }
    &__services {
      padding: 15rem 5rem 15rem 15rem;
      display: flex;
      justify-content: center;
      @include from-mid-screens {
        padding: 10rem 3rem;
      }
      @include tablet {
        padding: 3rem 2rem 9rem 2rem;
      }

      .icon-btn {
        @include mobile {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &__pills {
        padding: 8px;
        background: $primary-var-light-blue;
        border-radius: 40px;
        border: 2px solid $primary-var-grey;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          font-size: 14px;
          font-weight: 900;
          @media (max-width: 1200px) {
            font-size: 12px;
            letter-spacing: 0.2px;
          }
          @media (max-width: 1400px) {
            font-size: 13px;
          }
        }

        svg {
          padding: 5px;
          width: 50px !important;
          height: 50px !important;
          min-height: 50px !important;
          min-width: 50px !important;
          background: $primary-dark-blue;
          border-radius: 40px;
          margin-right: 5px;
          @media (max-width: 1400px) {
            width: 35px !important;
            height: 35px !important;
            min-height: 35px !important;
            min-width: 35px !important;
          }
          @media (max-width: 1200px) {
            width: 30px !important;
            height: 30px !important;
            min-height: 30px !important;
            min-width: 30px !important;
          }
        }
      }

      &__title {
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 29.7px */
        letter-spacing: 0.44px;
        margin-bottom: 10px;
        text-align: left;
        color: $primary-dark-blue;

        @include tablet {
          font-size: 20px;
        }
        @include mobile {
          font-size: 1.6em;
          margin-bottom: 20px;
        }
      }

      &__subtitle {
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 29.7px */
        letter-spacing: 0.44px;
        text-decoration: none !important;
        color: $primary-dark-blue;

        @include tablet {
          font-size: 0.813em;
        }
        @include mobile {
          margin-top: 5%;
        }
      }
    }
  }

  &__image {
    background-color: var(--black);
    overflow-y: hidden;
    max-height: inherit;
    img {
      width: 100%;
      object-fit: cover;
      opacity: 0.5;
    }
  }

  // Mobile
  @include mobile {
    display: flex;
    flex-direction: column;
    padding-top: var(--navbar-height);
    min-height: unset;

    &__info {
      z-index: 2;
      &-container {
        padding: 3rem;
      }
      & .main-button {
        display: block;
        margin: 0 auto;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      img {
        opacity: 0.3;
      }
    }
  }
}

// Why Nico
.landing--why-nico-client {
  display: grid;
  align-items: center;
  grid-template-columns: 40rem repeat(3, 25rem);
  column-gap: 2rem;

  &__margin {
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 0px;
    }
  }

  .gg-copy {
    margin-top: -10px;
  }

  .gg-lock {
    margin-top: -25px;
  }

  .gg-mail-open {
    margin-top: 5px;
  }

  &__container {
    justify-content: center;
    padding: 10rem 10%;

    @include tablet {
      padding: 5rem 1.5rem;
    }
  }

  &__content {
    display: grid;
    grid-gap: 2rem !important;
    grid-template-columns: repeat(3, 1fr); /* 3 tarjetas en fila para desktop */
    grid-auto-rows: minmax(350px, auto) !important; /* Altura mínima de las tarjetas */

    @include tablet {
      grid-template-columns: repeat(2, 1fr); /* 2 tarjetas en fila para tablet */
    }

    @include mobile {
      grid-template-columns: 1fr; /* 1 tarjeta en fila para móvil */
    }
  }

  &.client {
    grid-template-columns: 30rem repeat(2, 1fr);
    column-gap: 5%;
    grid-template-rows: unset;
  }

  &__tittlecontainer {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 135%; /* 18.9px */
      letter-spacing: 0.7px;
    }
  }

  &__titlecompanies-persons {
    max-width: 130rem;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 130%; /* 59.8px */
    letter-spacing: 0.92px;
    color: $secondary-blue;
    padding-bottom: 40px;
    margin: auto;
  }

  &__title {
    max-width: 100rem;
    font-size: 46px;
    font-style: normal;
    font-weight: 900;
    line-height: 130%; /* 59.8px */
    letter-spacing: 0.92px;
    margin: auto;
    color: $secondary-blue;
  }

  .why-nico--card {
    // border: 1px solid $primary-var-grey;
    padding: 3rem;
    border-radius: 4rem;
    min-height: 350px;
    width: 100%;
    background-color: #f3f5fd;

    @include from-mid-screens {
      height: 380px;
      min-height: auto;
    }

    @include tablet {
      height: 350px;
    }

    @include mobile {
      height: 100%;
    }

    &__icon-container {
      background-color: $secondary-blue !important;
    }

    &__icon {
      @include landing-icon-style();
    }
    &__title {
      margin-bottom: 1rem;
      line-height: 2.5rem;
      font-size: 22px;
      font-style: normal;
      font-weight: 900 !important;
      letter-spacing: 0.44px;
      text-transform: lowercase;
    }

    &__subtitle {
      margin-bottom: 1rem;
      line-height: 1.8rem;
      font-size: 14px;
      font-style: normal;
      font-weight: 800 !important;
      letter-spacing: 0.7px;
    }
    @include tablet {
      padding: 2.5rem;
    }
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    &-container {
      padding: 8rem 10% 3rem 10%;
    }

    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .why-nico--card {
      margin-bottom: 2rem;
    }
  }
}

.client-landing__horizontal-info {
  padding: 10rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    padding: 5rem 2rem;
  }

  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 43.2px */
    letter-spacing: 0.32px;
    text-align: center;
    color: $secondary-blue;
    @include mobile {
      line-height: 3.5rem;
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.7px;
    text-align: center;
    margin-bottom: 50px;
  }

  &__kindservices {
    padding: 10rem 15rem;
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 5rem 1.5rem;
    }

    &__margin {
      margin-bottom: 0px;
      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__card {
      background: white;
      border: 1px solid $primary-var-grey;
      border-radius: 40px;
      height: 500px;
      @include from-mid-screens {
        height: 380px;
      }
      @include mobile {
        height: 100%;
      }

      &__img {
        width: 100%;
        border-radius: 40px 40px 0px 0px;
      }

      &__containertext {
        padding: 20px 30px;
      }

      &__titlecard {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 24.3px */
        letter-spacing: 0.72px;
        margin-bottom: 15px;
      }

      &__textcard {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.17px;
        margin-bottom: 20px;
      }

      &__btnleft {
        position: absolute;
        bottom: 20px;
        right: 40px;

        @include mobile {
          display: grid;
          justify-content: normal;
          position: unset;
        }
      }
    }
  }
}

.container__accordion__client {
  width: 90%;
  margin: auto;

  .card {
    border: 0px;
  }

  .card-header {
    min-height: 60px !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px !important;
    border-radius: 20px !important;
    border: 1.5px solid $secondary-blue;
    background: #f3f5fd;

    &:hover {
      background: #b8c6f2;
      cursor: pointer;
    }
  }
}

.client-landing--form {
  background: $light-blue;
  padding: 30px;
  border-radius: 40px;
  border: 1px solid lightgray;

  p {
    color: $gray-800;
  }

  input[type='checkbox'] {
    height: fit-content;
    cursor: pointer;
  }

  .form-check {
    display: flex;
    align-items: center;
    p {
      color: gray;
      font-weight: bold;
    }
  }
}
