.info-title {
  color: $gray-600;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: 600;
  letter-spacing: normal;
  margin-bottom: 1rem;
}

.info-box {
  p {
    margin-bottom: 1rem;
    font-size: $font-size-sm;
    font-weight: 500;

    span {
      color: $gray-600;
      display: inline-block;
      font-weight: 600;
      letter-spacing: normal;

      &.w-sm {
        width: 65px;
      }
      &.w-md {
        width: 130px;
      }

      @include tablet {
        display: block;
      }
    }
  }
}

.info-attachments {
  display: grid;
  grid-template-areas: 'a b c .';
  grid-template-columns: 1.25fr 1.5fr 1.5fr 3fr;
  column-gap: 2rem;

  p {
    align-self: center;
    margin-bottom: 0;
  }

  @include tablet {
    grid-template-columns: 1.5fr 2fr 2fr 1fr;
  }

  @include mobile-sm {
    display: block;

    p,
    button {
      margin-bottom: 1rem;
    }
  }
}

.info-bg {
  &-primary {
    @include info-bg($primary-var-blue);
  }

  &-secondary {
    @include info-bg($secondary-var-blue);
  }

  &-info {
    @include info-bg($secondary-light-blue-30-light);
  }

  &-success {
    @include info-bg($var-success);
  }

  &-warning {
    @include info-bg($var-warning-yellow);
  }

  &-danger {
    @include info-bg($var-red);
  }

  &-light {
    @include info-bg($light-grey);
  }
}
