.partner-landing {
  &__title {
    font-weight: 900;
    text-align: center;
    font-size: 32px;
    @include mobile {
      line-height: 3.5rem;
      font-size: 28px;
    }
  }

  img {
    padding-left: 0px;
    @include mobile {
      padding: 0px;
    }
  }

  &__faqs {
    padding: 15rem 10rem 15rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heropartnerhow {
    background-color: $primary-light-blue;
    min-height: 70rem;
    max-height: 70rem;
    display: grid;

    @include mobile {
      padding: 8rem 0rem;
      max-height: none;
    }

    @include mid-screens {
      padding: 5rem 2rem;
      max-height: none;
    }

    &__card {
      padding: 20px 20px 20px 0px;
      @include mobile {
        border-radius: 50px;
        padding: 10px 15px 10px 15px !important;
      }
      @include tablet {
        padding: 5px;
      }
      @include mid-screens {
        padding: 20px;
      }
      p {
        color: $primary-dark-blue !important;
        font-size: 12px !important;
        font-weight: 800 !important;
        line-height: 135% !important;
        letter-spacing: 0.7px !important;
        margin-bottom: 0px !important;
      }

      &__container {
        padding: 10px 5px;
        border-radius: 100px;
        // border: 2px solid $primary-dark-blue;
        background: white;
        margin-bottom: 25px;
        @include mobile-sm {
          padding: 5px 0px !important;
        }

        &__iconcontainer {
          width: 65px;
          height: 65px;
          margin-right: -10px;
          background: $secondary-light-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          @include mobile {
            width: 40px;
            height: 40px;
          }
          @include mid-screens {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  }

  &__subtitle {
    font-size: 18px;
    font-style: normal;
    text-align: center;
    margin-bottom: 4rem;
    font-weight: 900;
    line-height: 135%; /* 24.3px */
    letter-spacing: 0.72px;
  }

  h3 {
    color: $primary-dark-blue;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 29.7px */
    letter-spacing: 0.44px;
    margin-bottom: 30px;
    @include mobile {
      margin-bottom: 15px;
      font-size: 25px;
      text-align: center;
    }
  }

  h6 {
    color: $primary-dark-blue;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.7px;
    margin-top: 30px;
    @include mobile {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  &__kindservices {
    padding: 10rem 15rem;
    display: flex;
    flex-direction: column;
    background-color: $primary-var-light-blue;

    @include mobile {
      padding: 5rem 1.5rem !important;
    }

    @include from-mid-screens {
      padding: 10rem 3rem;
    }

    &__margin {
      margin-bottom: 0px;
      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__card {
      background: white;
      border: 1px solid $primary-var-grey;
      border-radius: 40px;
      height: 430px;

      @include from-mid-screens {
        height: 480px;
      }
      @include mobile {
        height: 100% !important;
      }

      &__img {
        width: 100%;
        border-radius: 40px 40px 0px 0px;
      }

      &__containertext {
        padding: 20px 30px;
      }

      &__titlecard {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 24.3px */
        letter-spacing: 0.72px;
        margin-bottom: 15px;
      }

      &__textcard {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.17px;
        margin-bottom: 20px;
      }

      &__btnleft {
        position: absolute;
        bottom: 20px;
        right: 40px;

        @include mobile {
          display: grid;
          justify-content: normal;
          position: unset;
        }
      }
    }
  }

  &__heropartner {
    background-color: $primary-light-blue;
    min-height: 70rem;
    max-height: 70rem;
    display: grid;

    @include mobile {
      max-height: none;
    }

    &__hidemobile {
      @include mobile {
        display: none;
      }
    }

    &__container {
      padding: 0rem 0rem 0rem 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
      background-image: url('./app/assets/images/Landing/PartnerLanding/partner_info_container_desktop.webp');
      background-repeat: no-repeat;
      background-size: contain;

      @include tablet {
        padding: 3rem;
        background-image: none !important;
      }

      h1 {
        color: $primary-dark-blue;
        font-size: 46px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%; /* 59.8px */
        letter-spacing: 0.92px;
        @include mobile {
          font-size: 30px;
        }
      }

      p {
        color: $primary-dark-blue;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        margin-bottom: 15px;
      }
    }
  }

  &__heropartnercontainerhow {
    background-color: $primary-light-blue;
    min-height: 70rem;
    max-height: 70rem;
    display: grid;

    @include mobile {
      max-height: none;
    }

    &__hidemobile {
      @include mobile {
        display: none;
      }
    }

    &__container {
      padding: 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
      background-image: url('./app/assets/images/Landing/PartnerLanding/partner_info_container_desktop.webp');
      background-repeat: no-repeat;
      background-size: contain;

      @include tablet {
        padding: 3rem;
        background-image: none !important;
      }

      @include mobile {
        padding: 3rem;
        display: flow;
        background-image: none !important;
      }

      h1 {
        color: $primary-dark-blue;
        font-size: 46px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%; /* 59.8px */
        letter-spacing: 0.92px;

        @include from-mid-screens {
          font-size: 35px;
        }
        @include mobile {
          font-size: 25px;
        }
        @include mobile-sm {
          font-size: 20px;
        }
      }

      p {
        color: $primary-dark-blue;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        margin-bottom: 15px;
      }
    }
  }

  &__suportpartner {
    margin-bottom: 25px;

    &__subtitle {
      margin-left: -2%;
      color: $primary-dark-blue;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 19.5px */
      letter-spacing: 1.17px;

      @include mobile {
        margin-left: 20px;
        margin-right: 0px;
      }
    }
    &__text {
      margin-left: -2%;
      color: $primary-dark-blue;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 1.17px;

      @include mobile {
        margin-left: 20px;
        margin-right: 0px;
      }
    }
    &__icon {
      @include mobile {
        display: flex;
        justify-content: flex-end;
      }
    }
    &__iconcontainer {
      width: 65px;
      height: 65px;
      margin-right: -10px;
      background: $primary-light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      @include from-mid-screens {
        width: 50px;
        height: 50px;
      }
    }
  }

  //Company Logos Section
  &__insurance-company-logo {
    column-gap: 15rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80vw;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      img {
        @include img-grayscale();
        width: 120px;
      }
    }

    @include tablet {
      width: 100vw;
      column-gap: 10rem;
    }

    @include mobile {
      column-gap: 1.5rem;

      &__logo {
        padding: 0 3rem;

        img {
          width: 120px;
        }
      }
    }
  }

  &__number {
    padding: 20rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      padding: 10rem 2rem;
    }

    &__card {
      background: $primary-var-blue;
      text-align: center;
      padding: 20px 50px;
      border-radius: 40px;
      height: 220px;

      @include from-mid-screens {
        padding: 20px;
        height: 200px;
      }

      @include mobile {
        margin-bottom: 20px;
        height: auto;
      }

      &__titlecard {
        font-size: 55px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 43.2px */
        letter-spacing: 0.32px;
        margin-left: -10px;

        @include mobile {
          font-size: 50px;
        }
        @include from-mid-screens {
          font-size: 40px;
        }
      }

      &__textcard {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.956px;
      }
    }
  }

  &__faqs {
    padding: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      padding: 10rem 0rem 2rem 0rem;
    }
  }

  &__demo {
    padding: 0rem 10rem 10rem 10rem;
    @include tablet {
      padding: 6rem 4rem;
    }

    .icon-btn {
      @include mobile {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &__img {
      border-radius: 40px;
      @include from-mid-screens {
        width: 95%;
      }
      @include tablet {
        width: 100%;
      }
    }

    &__title {
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 135%; /* 29.7px */
      letter-spacing: 0.44px;
      margin-bottom: 15px;
      text-align: left;

      @include tablet {
        text-align: left;
        font-size: 16px;
      }
      @include mobile {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 5%;
      }
    }
  }
}

// Why Nico
.landing--why-nico-partner {
  display: grid;
  align-items: center;
  grid-template-columns: 40rem repeat(3, 25rem);
  grid-template-rows: 32rem;
  column-gap: 2rem;

  &__margin {
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 0px;
    }
  }

  .gg-copy {
    margin-top: -10px;
  }

  .gg-lock {
    margin-top: -25px;
  }

  .gg-mail-open {
    margin-top: 5px;
  }

  &__container {
    justify-content: center;
    padding: 10rem 10%;

    @include tablet {
      padding: 5rem 1.5rem;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &.client {
    grid-template-columns: 30rem repeat(2, 1fr);
    column-gap: 5%;
    grid-template-rows: unset;
  }

  &__tittlecontainer {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  &__title {
    max-width: 100rem;
    line-height: 4.3rem;
    text-align: center;
    font-size: 32px;
  }

  .why-nico--card {
    // border: 1px solid $primary-var-grey;
    padding: 3rem;
    border-radius: 4rem;
    height: 300px;
    width: 100%;
    background-color: $primary-var-light-blue;

    @include tablet {
      height: 350px;
    }

    @include mobile {
      height: 100% !important;
    }

    @include from-mid-screens {
      height: 335px;
    }

    &__icon-container {
      background-color: $primary-light-blue !important;

      i {
        color: $primary-dark-blue !important;
      }
    }

    &__icon {
      @include landing-icon-style();
    }
    &__title {
      margin-bottom: 1rem;
      line-height: 1.8rem;
      font-size: 22px;
      font-style: normal;
      font-weight: 900 !important;
      letter-spacing: 0.44px;
      text-transform: lowercase;
    }

    &__subtitle {
      margin-bottom: 1rem;
      line-height: 1.8rem;
      font-size: 14px;
      font-style: normal;
      font-weight: 800 !important;
      letter-spacing: 0.7px;
    }
    @include tablet {
      padding: 2.5rem;
    }
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    &-container {
      padding: 8rem 10% 3rem 10%;
    }

    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .why-nico--card {
      margin-bottom: 2rem;
    }
  }
}

// Suport Section
.partner-landing__suport {
  &__container {
    padding: 10rem 10rem 5rem 10rem;

    @include tablet {
      padding: 8rem 8rem 5rem 8rem;
    }
    @include mobile {
      padding: 5rem 1.5rem 5rem 1.5rem;
    }
    @include from-mid-screens {
      padding: 8rem 4rem 5rem 4rem;
    }
  }

  &__title {
    padding: 0px 20px;
    font-size: 58px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 43.2px */
    letter-spacing: 0.32px;

    @include mobile {
      font-size: 30px;
      text-align: center;
    }
  }

  &__cardcontainer {
    background-color: $primary-var-light-grey;
    border: 1px solid $primary-var-grey;
    border-radius: 40px;
    min-height: 180px;
  }

  &__cardimg {
    width: 100%;
    height: 100%;
  }

  &__containertext {
    padding: 10px 30px;
    margin: auto;
  }

  &__titlecard {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 43.2px */
    letter-spacing: 0.32px;

    @include mobile {
      font-size: 20px;
    }
  }
}

.container__accordion__partner {
  width: 90%;
  margin: auto;

  .card {
    border: 0px;
  }

  .card-header {
    min-height: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px !important;
    border-radius: 20px !important;
    border: 1.5px solid $primary-light-blue;
    background: $primary-var-light-blue;

    &:hover {
      background: $primary-var-blue;
      cursor: pointer;
    }
  }
}
