.chart-container {
  font-size: $font-size-sm;
  color: $black;

  .recharts-bar {
    &:hover {
      .recharts-text.recharts-label {
        fill: $black;
      }
    }
  }

  .recharts-legend-wrapper {
    display: flex;
    justify-content: flex-end;

    .recharts-legend-item {
      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: center !important;
      margin: 0 !important;

      .recharts-surface {
        margin-left: 1rem;
      }
    }
  }
}
