.notification-list {
  --notification-max-height: 30rem;
  --header-height: 4rem;
  max-height: var(--notification-max-height);

  &--header {
    padding: 0 2rem;

    height: var(--header-height);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--primary-var-grey);
  }

  &--items {
    overflow-y: auto;
    max-height: calc(var(--notification-max-height) - var(--header-height));
    @include scrollbar();
  }

  &--item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primary-var-grey);
    transition: 0.3s;

    &.unread {
      background-color: var(--primary-var-light-blue);
    }

    &:hover {
      background-color: var(--var-warning-yellow);
    }

    &__container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1rem;
    }

    &__icon {
      min-width: 4rem;
      min-height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__body {
      width: 100%;
      padding: 0 1rem;
    }
  }
}
