.beneficiary-statistic-card {
  display: grid;
  grid-template-columns: 3fr 7fr 1fr;
  border-radius: 3rem;
  overflow: hidden;
  background-color: $primary-var-light-grey;
  padding: 0.5rem 1rem;

  @include from-mid-screens {
    grid-template-columns: 2fr 2fr 1fr;
    gap: 1rem;
    padding: 1rem;

    &--content > * {
      text-align: center;
      margin: 0 auto;
    }
  }

  &--image {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    width: 5rem;
    height: 5rem;

    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
}
