// General

.broker-landing-broker {
  background: #f4fefe; /* Color para broker */
}

.broker-landing-partner {
  background: #f4fbfe; /* Color para partner */
}

.broker-landing-insured {
  background: #f3f5fd; /* Color para insured */
}

.broker-landing-default {
  background-color: $light-blue;
}

.text-dark-blue {
  color: $primary-dark-blue;
}

.w-mobile-100 {
  @include mobile {
    width: 100% !important;
  }
}

.line-height-header {
  @include mobile {
    line-height: 35px;
  }
}

.container__accordion {
  width: 90%;
  margin: auto;

  .card {
    border: 0px;
  }

  .card-header {
    min-height: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px !important;
    border-radius: 20px !important;
    border: 1.5px solid $secondary-light-blue;
    background: $light-blue;

    &:hover {
      background: $secondary-light-blue-30-light;
      cursor: pointer;
    }
  }
}

.broker-landing__horizontal-info {
  padding: 10rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    padding: 5rem 2rem;
  }

  &__title {
    font-weight: 900;
    margin-bottom: 4rem;
    text-align: center;
    font-size: 32px;
    @include mobile {
      line-height: 3.5rem;
      font-size: 28px;
    }
  }

  &__herobrokerplans {
    background-color: $primary-dark-blue;
    min-height: 70rem;
    max-height: 70rem;
    display: grid;

    @include mobile {
      padding: 8rem 0rem;
      max-height: none;
    }

    @include mid-screens {
      padding: 5rem 2rem;
      max-height: none;
    }

    &__card {
      padding: 20px 20px 20px 0px;
      @include mobile {
        border-radius: 50px;
        padding: 10px 15px 10px 15px !important;
      }
      @include tablet {
        padding: 5px;
      }
      @include mid-screens {
        padding: 20px;
      }
      p {
        color: $primary-dark-blue !important;
        font-size: 12px !important;
        font-weight: 800 !important;
        line-height: 135% !important;
        letter-spacing: 0.7px !important;
        margin-bottom: 0px !important;
      }

      &__container {
        padding: 10px 5px;
        border-radius: 100px;
        // border: 2px solid $secondary-light-blue;
        background: white;
        margin-bottom: 25px;
        @include mobile-sm {
          padding: 5px 0px !important;
        }

        &__iconcontainer {
          width: 65px;
          height: 65px;
          margin-right: -10px;
          background: $secondary-light-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          @include mobile {
            width: 40px;
            height: 40px;
          }
          @include mid-screens {
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    &__container {
      padding: 8rem;
      display: flex;
      align-items: center;
      max-height: inherit;
      background-image: url('./app/assets/images/Landing/BrokerLanding/broker_info_container_desktop.png');
      background-repeat: no-repeat;
      background-size: contain;

      @include tablet {
        padding: 3rem;
        background-image: none;
      }

      @include mobile {
        display: flow;
        background-image: none;
      }

      h1 {
        color: white;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 130%; /* 59.8px */
        letter-spacing: 0.92px;

        @include mobile {
          font-size: 25px;
        }
        @include mobile-sm {
          font-size: 20px;
        }
      }

      p {
        color: white;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        margin-bottom: 15px;
      }
    }
  }

  //Company Logos Section

  &__insurance-company-logo {
    column-gap: 15rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80vw;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      img {
        @include img-grayscale();
        width: 120px;
      }
    }

    @include tablet {
      width: 100vw;
      column-gap: 10rem;
    }

    @include mobile {
      column-gap: 1.5rem;

      &__logo {
        padding: 0 3rem;

        img {
          width: 120px;
        }
      }
    }
  }

  &__number {
    padding: 20rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      padding: 10rem 2rem;
    }

    &__card {
      background: #ccf9fd;
      text-align: center;
      padding: 20px 50px;
      border-radius: 40px;
      height: 220px;

      @include from-mid-screens {
        padding: 20px;
        height: 200px;
      }

      @include mobile {
        margin-bottom: 20px;
        height: auto;
      }

      &__titlecard {
        font-size: 55px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 43.2px */
        letter-spacing: 0.32px;
        margin-left: -10px;

        @include mobile {
          font-size: 50px;
        }
        @include from-mid-screens {
          font-size: 40px;
        }
      }

      &__textcard {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.956px;
      }
    }
  }

  &__kindservices {
    padding: 10rem 15rem;
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 5rem 1.5rem;
    }

    @include mid-screens {
      padding: 5rem 3rem;
    }

    &_bgblue {
      background: $light-blue;
    }

    &__margin {
      margin-bottom: 0px;
      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__card {
      background: $white;
      border: 1px solid $primary-var-grey;
      border-radius: 40px;
      height: 560px;
      @include mobile {
        height: 100%;
      }

      &__img {
        width: 100%;
        border-radius: 40px 40px 0px 0px;
      }

      &__containertext {
        padding: 20px 30px;
        svg {
          width: 100%;
          margin-top: 0px !important;
        }
      }

      &__titlecard {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 24.3px */
        letter-spacing: 0.72px;
        margin-bottom: 15px;
      }

      &__textcard {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.17px;
        margin-bottom: 20px;
      }

      &__btnleft {
        position: absolute;
        bottom: 20px;
        right: 40px;

        @include mobile {
          display: grid;
          justify-content: normal;
          position: unset;
        }
      }
    }
  }
  &__uipublicity {
    background: linear-gradient(180deg, rgba(244, 245, 246, 0) 40%, #f4f5f6 40%, #f4f5f6 100%);
    padding: 10rem 0rem 0rem 10rem;
    @include mobile {
      background-color: $primary-var-light-grey;
      padding: 2rem 2rem 0rem 2rem;
    }
    .icon-btn {
      @include mobile {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &__img {
      width: 100%;
      border-radius: 40px 40px 0px 0px;
    }

    &__title {
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 135%; /* 29.7px */
      letter-spacing: 0.44px;
      margin-top: 15%;
      margin-bottom: 15px;
      text-align: left;

      @include tablet {
        text-align: left;
        font-size: 16px;
      }
      @include mobile {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 5%;
      }
    }
  }

  &__kindplans {
    padding: 0px 10%;

    @include mobile {
      padding: 0px 3%;
    }

    @include mid-screens {
      padding: 0px 5%;
    }

    .icon-btn {
      width: 80%;
      margin: auto;
    }

    svg {
      width: 100%;
      margin-top: -5px;
    }

    &__card {
      background: $light-blue;
      // border: 1px solid $primary-var-grey;
      border-radius: 41px;
      height: 620px;
      @include mobile {
        height: 100%;
      }
    }

    &__bluecontainer {
      height: 200px;
      width: 100%;
      background: $primary-dark-blue;
      border-radius: 40px 40px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 10px 15%;

      &__title {
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 135%; /* 29.7px */
        letter-spacing: 0.44px;
        color: $secondary-light-blue;
      }

      &__subtitle {
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.17px;
        color: white;
      }
    }
  }

  &__tabletext {
    &__hidemobile {
      @include mobile {
        display: none;
      }
    }

    &__showmobile {
      display: none;
      @include mobile {
        display: flex;
      }
    }

    &__roundsm {
      @include mobile {
        border-radius: 0px 0px 40px 40px !important;
      }
    }
    &__arrow {
      width: 100%;
      padding-right: 0px;
      margin-right: -8px !important;
    }

    &__circleicon {
      background: $secondary-light-blue;
      padding: 13px;
      width: 70px;
      height: 70px;
      border-radius: 100px;

      @include mobile {
        width: 50px;
        height: 50px;
      }

      @include mid-screens {
        width: 55px;
        height: 55px;
      }

      &__text {
        color: white;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%;
        letter-spacing: 0.72px;
        padding: 0px 10px 0px 0px;
        @include mobile {
          font-size: 15px;
        }
      }
    }

    &__container {
      display: flex;
      align-items: center;
      background: $primary-dark-blue;
      padding: 14px !important;
      border-radius: 40px;
      margin: 10px 0px;
      @include mobile {
        border-radius: 0px;
        margin: 0px;
      }
    }
  }

  &__tablenumber {
    &__container {
      padding: 2rem 3rem;
      @include tablet {
        padding: 2rem 1rem;
      }
      @include xl-screens {
        max-width: 1800px;
        margin: auto;
      }
    }
    &__sinnico {
      padding-top: 13px;
      @include tablet {
        padding-top: 8px;
      }
    }

    &__connico {
      @include tablet {
        font-size: 12px;
      }
    }

    &__table {
      background: white;
      border-radius: 40px 40px 0px 0px;
      border: 1px solid $primary-var-grey;

      &__tableinner {
        padding: 1rem 2rem;
        @include mobile {
          padding: 0.5rem 1rem;
        }

        &__signitalic {
          font-family: Montserrat;
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          line-height: 135%; /* 13.5px */
          letter-spacing: 0.6px;
          margin-top: 5px;
        }

        &__borderright {
          border-right: 1px solid $primary-var-grey;
        }

        &__header {
          padding: 12px 0px;
        }

        &__numberdefault {
          background: $primary-var-light-grey;
          padding: 8px;
          width: 55%;
          text-align: center;
          border-radius: 40px;
          @include mobile {
            width: 65%;
          }
        }

        &__numbernico {
          background: $secondary-light-blue;
          padding: 8px;
          width: 55%;
          text-align: center;
          border-radius: 40px;
          @include mobile {
            width: 65%;
          }
        }
      }
    }

    &__tablesecondrow {
      background: white;
      border-radius: 0px;
      border-right: 1px solid $primary-var-grey;
      border-left: 1px solid $primary-var-grey;
      border-bottom: 1px solid $primary-var-grey;
    }

    &__tablelastrow {
      background: white;
      border-radius: 0px 0px 40px 40px;
      border-right: 1px solid $primary-var-grey;
      border-left: 1px solid $primary-var-grey;
      border-bottom: 1px solid $primary-var-grey;
      @include mobile {
        border-radius: 0px;
      }
    }
  }

  &__faqs {
    padding: 5rem 10rem 10rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      padding: 10rem 0rem 2rem 0rem;
    }
  }

  &__demo {
    padding: 0rem 10rem 10rem 10rem;
    @include tablet {
      padding: 6rem 4rem;
    }

    .icon-btn {
      @include mobile {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &__img {
      border-radius: 40px;
      @include from-mid-screens {
        width: 95%;
      }
      @include tablet {
        width: 100%;
      }
    }

    &__title {
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: 135%; /* 29.7px */
      letter-spacing: 0.44px;
      margin-bottom: 15px;
      text-align: left;

      @include tablet {
        text-align: left;
        font-size: 16px;
      }
      @include mobile {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 5%;
      }
    }
  }
}

.broker-landing-features-container {
  min-height: 300px;
}

// Suport Section
.broker-landing__suport {
  &__container {
    padding: 10rem 10rem 20rem 10rem;

    @include tablet {
      padding: 8rem 8rem 16rem 8rem;
    }
    @include mobile {
      padding: 5rem 5rem 10rem 5rem;
    }
  }

  &__title {
    padding: 0px 20px;
    font-size: 58px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 43.2px */
    letter-spacing: 0.32px;

    @include mobile-sm {
      font-size: 25px !important;
      padding: 0px;
      margin-bottom: 20px !important;
    }

    @include mobile {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px !important;
    }

    @include from-mid-screens {
      font-size: 40px;
      line-height: 120%;
    }
  }

  &__cardcontainer {
    background-color: $primary-dark-blue;
    // border: 1px solid $primary-var-grey;
    color: white !important;
    border-radius: 40px;
    min-height: 180px;
  }

  &__cardimg {
    width: 100%;
    height: 100%;
  }

  &__containertext {
    padding: 10px 30px;
    margin: auto;
  }

  &__titlecard {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 43.2px */
    letter-spacing: 0.32px;
    color: white !important;

    @include mobile {
      font-size: 20px;
    }
  }
}

.broker-landing-card {
  min-height: 680px !important;
}
.broker-landing-feature {
  margin-bottom: 50px;
  @include tablet {
    margin-bottom: 10px;
    min-height: 80px;
  }
}
.broker-landing-card {
  @include tablet {
    min-height: 650px;
  }
  @include mobile {
    min-height: 300px;
  }
}
