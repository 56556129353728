@mixin invisible-input {
  border: 1px solid $gray-400;
  border-radius: 25px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;

  & input {
    border: none;

    &::-webkit-input-placeholder {
      font-size: 1.1rem;
    }

    &:-moz-placeholder {
      text-align: center;
    }

    &:focus {
      outline: none !important;
    }
  }
}

.search-filter--container {
  @include invisible-input();
  .form-control {
    transition: 0.3s;

    &:focus {
      box-shadow: none;
    }
  }
  i {
    flex-shrink: 0;
  }
}

.dates-filter--container {
  @include invisible-input();
  justify-content: flex-end;
  align-items: center;
  max-width: 37rem;

  input {
    border-bottom: 1px solid $gray-400;
  }

  i {
    align-self: center;
    flex-shrink: 0;
  }

  label {
    font-size: 1rem;
    margin: 0;
  }
}

.index-filters {
  &--main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &--secondary {
    transition: all 0.5s ease-in-out;
    visibility: hidden;
    max-height: 0;

    &.show {
      visibility: visible;
      max-height: 11rem;

      @include tablet {
        max-height: 27rem;
      }

      @include mobile {
        max-height: 44rem;
      }
    }

    .remote-select {
      .form-group {
        margin: 0;

        .react-select__control {
          padding-left: 1.5rem;
          min-height: 4rem;
          height: 4rem;
        }
      }
    }
  }

  &--right-section {
    display: flex;
    align-items: inherit;
    justify-content: flex-end;
    margin-right: 0 !important;
    & div:last-child {
      margin-right: 0 !important;
    }
  }
}

.step-button-order {
  display: flex;
  flex-direction: row;

  div:nth-child(3) {
    @media (max-width: 991px) {
      order: 5;
    }
  }

  div:nth-child(5) {
    @media (max-width: 991px) {
      order: 4;
    }
  }
}
