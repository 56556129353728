.whatsapp-button {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  background: rgb(85, 255, 111);
  background: linear-gradient(180deg, rgba(85, 255, 111, 1) 0%, rgba(39, 181, 34, 1) 100%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid white;
  img {
    width: 50%;
    height: auto;
  }
}