.custom-datatable {
  header {
    padding: 0 0 1.5rem 0;
  }

  .datatable-sub-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .date-input:not(:last-child) {
      margin-right: 1rem;
    }

    .form-group {
      margin: 0;
      padding-top: 1.5rem;
    }
  }

  .datatable-basic-buttons {
    display: flex;

    div {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
  }
}

// Datatable main info

.datatable.main-info {
  display: grid;
  grid-template-columns: 1fr 5fr;
  width: 100%;
  padding: 0;
  pointer-events: none;

  .main-info--icon,
  .main-info--data {
    padding: 0 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rotate-icon-45 {
      i {
        transform: rotate(45deg);
      }
    }
  }
  .main-info--icon {
    align-items: center;
  }
  .main-info--data {
    p {
      display: block;
      box-sizing: border-box;
      margin: 0;
      padding: 0.25rem 0;
    }
  }
}
