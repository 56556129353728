.comment-box {
  display: grid;

  &.with-title {
    border-radius: 3rem;
    border: 1px solid var(--primary-var-grey) !important;
    grid-template-rows: 5rem;
    padding: 2rem 4rem;
  }

  &__title {
    align-items: center;
    display: flex;
    margin-bottom: 1.25rem;
  }

  .form-group {
    margin-bottom: 2rem;
    padding: 0;

    textarea {
      border-color: var(--primary-var-grey);
      border-radius: 2rem;
      height: 100%;
      padding: 2rem;

      &.custom-height {
        min-height: 32rem;
      }

      &.small {
        min-height: unset;
      }

      @include scrollbar;
    }
  }
}
