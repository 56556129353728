.custom-header {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: start;
    min-height: 44px;

    h5 {
      align-items: center;
      display: flex;
    }
  }

  &__button {
    align-items: center;
    column-gap: 2rem;
    display: inline-flex;
    flex-direction: row-reverse;

    &.two-buttons {
      grid-template-columns: 1.28fr 1fr;
    }

    @include tablet {
      &.two-buttons {
        grid-template-columns: repeat(2, 1fr);
      }

      a,
      button {
        width: 100% !important;
      }
    }

    @include mobile {
      display: block;

      &.small-icon-btn {
        display: inline-flex;
        grid-template-columns: auto 1fr;
      }

      a,
      button {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__form {
    margin: 3rem 0;

    .section-field {
      margin-bottom: 2rem;
    }
  }
}
